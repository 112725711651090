import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  EventDocumentDto,
  EventDocumentListItemDto,
  EventDocumentsClient,
} from "../../../application/service/ApiClient";
import { saveAs } from "file-saver";
import { useLangs } from "../../../application/hooks/useLangs";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FileViewDialog from "./FileViewDialogComponent";

type Props = {
  eventId: number | undefined;
};

const AgendaDocumentsComponent: React.FC<Props> = ({ eventId }) => {
  const ERROR = useLangs("ERROR");
  const [currentLoading, setCurrentLoading] = useState(false);
  const [eventDocuments, setEventDocuments] = useState<
    EventDocumentListItemDto[]
  >([]);
  const [isViewDialogVisible, setViewDialogVisible] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<
    EventDocumentDto | undefined
  >(undefined);

  useEffect(() => {
    getEventDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getEventDocuments = async () => {
    if (eventId !== undefined) {
      try {
        const eventDocumentsClient = new EventDocumentsClient();
        let files = await eventDocumentsClient.getEventDocuments(
          eventId,
          undefined,
          undefined
        );
        setEventDocuments(files.items || []);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(ERROR, { variant: "error" });
      }
    }
  };

  const downloadEventFile = async (id: number) => {
    setCurrentLoading(true);
    try {
      const eventDocumentsClient = new EventDocumentsClient();
      let eventDocument = await eventDocumentsClient.getEventDocumentById(id);
      let bytes = base64ToArrayBuffer(eventDocument.content || "");
      let blobData = new Blob([bytes], { type: eventDocument.contentType });
      saveAs(blobData, eventDocument.displayName);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setCurrentLoading(false);
  };

  const handleViewDocument = async (id: number) => {
    setCurrentLoading(true);
    try {
      const eventDocumentsClient = new EventDocumentsClient();
      const eventDocument = await eventDocumentsClient.getEventDocumentById(id);
      setCurrentDocument(eventDocument);
      setViewDialogVisible(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setCurrentLoading(false);
  };

  const base64ToArrayBuffer = (base64: string) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  return (
    <React.Fragment>
      <div className="segment">
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>{useLangs("DOCUMENT_NAME")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventDocuments &&
              eventDocuments.map((file, index) => {
                return (
                  <TableRow key={`attachment${index + 1}`}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{file.fileName}</TableCell>
                    <TableCell>
                      <LoadingButton
                        loading={currentLoading}
                        loadingPosition="start"
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                        className="mainIconBtn"
                        onClick={() => downloadEventFile(file.id!)}
                      ></LoadingButton>
                      <LoadingButton
                        loading={currentLoading}
                        loadingPosition="start"
                        startIcon={<OpenInNewIcon />}
                        variant="outlined"
                        className="mainIconBtn"
                        onClick={() => handleViewDocument(file.id!)}
                      ></LoadingButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <FileViewDialog
        fileName={(currentDocument && currentDocument.fileName) || ""}
        downloadPath={`/EventDocuments/GetDocumentContent/${currentDocument?.id}`}
        open={isViewDialogVisible}
        onClose={() => setViewDialogVisible(false)}
      />
    </React.Fragment>
  );
};

export default AgendaDocumentsComponent;
function enqueueSnackbar(ERROR: string, arg1: { variant: string }) {
  throw new Error("Function not implemented.");
}
