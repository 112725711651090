import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import agendaReducer from "../slices/agendaSlice";
import showEventReducer from "../slices/showEventSlice";

export const store = configureStore({
  reducer: {
    agenda: agendaReducer,
    showEvent: showEventReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
