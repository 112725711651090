import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./Logo";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import authService from "../../../application/service/AuthService";
import { Context } from "../../../store/context";
import { accountsService } from "../../../application/service";
import Quorum from "./Quorum";
import { useLangs } from "../../../application/hooks/useLangs";
import { AccountClient } from "../../../application/service/ApiClient";

type Props = {
  onMobileNavOpen: () => void;
};

const DashboardNavbar = ({ onMobileNavOpen }: Props) => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [photo, setPhoto] = useState<string>("/images/user.svg");
  const {
    dispatch,
    state: { lang },
  } = useContext(Context);
  useEffect(() => {
    const currentUser = authService.getUser();
    setCurrentUser(currentUser);
    if (currentUser == null) {
      window.location.href = "/account/login";
    }
    if (currentUser.photo !== undefined) {
      setPhoto(currentUser.photo);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await accountsService.logout();
      authService.logout();
      window.location.href = "/account/login";
    } catch (e) {
      console.error(e);
    }
  };

  const { id } = useParams();
  const loadUser = async () => {
    try {
      const accountsClient = new AccountClient();
      const account = await accountsClient.getAccount(id as string);
      console.log(account.language);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (id !== undefined) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent<"KZ" | "RU">) => {
    const value = event.target.value;
    if (value === "KZ" || value === "RU") {
      dispatch({
        type: "SET_LANG",
        lang: value,
      });
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    location: { pathname },
  } = window;
  const CONFIRM = useLangs("CONFIRM");
  const CANCEL = useLangs("CANCEL");
  const WANT_TO_EXIT = useLangs("WANT_TO_EXIT");

  return (
    <AppBar elevation={0} className="navbar">
      <Toolbar sx={{ height: 80 }}>
        <Hidden lgUp>
          <IconButton color="secondary" onClick={onMobileNavOpen} size="medium">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Logo />
        {pathname === "/events/live" && <Quorum />}
        <Box sx={{ flexGrow: 1 }} />
        <FormControl
          variant="standard"
          style={{ marginRight: "10px", border: "none" }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            onChange={handleChange}
          >
            <MenuItem style={{ color: "#004479" }} value={"KZ"}>
              КК
            </MenuItem>
            <MenuItem style={{ color: "#004479" }} value={"RU"}>
              RU
            </MenuItem>
          </Select>
        </FormControl>
        <Box className="navbarRight">
          <img src={photo} alt="avatar" className="avatar" />
          <p style={{ color: "#004479" }}>
            {currentUser && currentUser.given_name}
          </p>
        </Box>
        <IconButton onClick={handleOpen}>
          <ExitToApp style={{ color: "#004479" }} />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {WANT_TO_EXIT}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#004479" }} onClick={handleLogout}>
              {CONFIRM}
            </Button>
            <Button style={{ color: "#004479" }} onClick={handleClose}>
              {CANCEL}
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
