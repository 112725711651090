import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import authService from "../application/service/AuthService";
import { loadAgendaPageEventAsync } from "../application/slices/agendaSlice";
import { loadShowEventPageEventAsync } from "../application/slices/showEventSlice";

const GlobalInit = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authService.isCurrentUserAnAdmin()) {
      navigation("/events");
      dispatch(loadShowEventPageEventAsync());

      return;
    }

    if (authService.isCurrentUserAPanel()) {
      dispatch(loadShowEventPageEventAsync());
      navigation("/events/live");
      return;
    }

    if (authService.isCurrentUserADelegate()) {
      dispatch(loadAgendaPageEventAsync());
      navigation("/agenda");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GlobalInit;
