import { Box } from "@material-ui/system";
import { Close, Visibility } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { animated } from "react-spring";
import {
  EventQuestionDto,
  EventQuestionUploadedFileVm,
} from "../../../application/service/ApiClient";
import { useLangs } from "../../../application/hooks/useLangs";
import FileViewDialog from "./FileViewDialogComponent";

type Props = {
  questionInfo: EventQuestionDto | undefined;
  handleVisiblePlanInfoCard: any;
  style: any;
};

const ShowQuestionInfo: React.FC<Props> = ({
  questionInfo,
  handleVisiblePlanInfoCard,
  style,
}) => {
  const [isViewDialogVisible, setViewDialogVisible] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<
    EventQuestionUploadedFileVm | undefined
  >(undefined);

  useEffect(() => {
    if (currentDocument === undefined) {
      setViewDialogVisible(false);
    } else {
      setViewDialogVisible(true);
      handleVisiblePlanInfoCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocument]);

  return (
    <animated.div className="segmentPushable" style={style}>
      <IconButton
        className="cancelIconWrap"
        onClick={handleVisiblePlanInfoCard}
      >
        <Close style={{ color: "#004479" }} />
      </IconButton>
      <Box>
        <Typography variant="h3" component="div" gutterBottom>
          {useLangs("QUESTION")}: {questionInfo?.title}
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
          {useLangs("SPEAKER")}: {questionInfo?.proposedBy}
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          {useLangs("FILES")}:
        </Typography>
        <div className="attachmentsWrap">
          {questionInfo?.attachments?.map((attachment) => (
            <Box className="pdfBox" key={attachment.id}>
              <div className="title">{attachment.name}</div>
              <IconButton
                className="eyeIconWrap"
                onClick={() => setCurrentDocument(attachment)}
              >
                <Visibility style={{ color: "#004479" }} />
              </IconButton>
            </Box>
          ))}
        </div>
      </Box>
      <FileViewDialog
        fileName={(currentDocument && currentDocument.name) || ""}
        downloadPath={`/EventQuestionAttachments/GetEventQuestionDocumentContent/${currentDocument?.id!}`}
        open={isViewDialogVisible}
        onClose={() => setCurrentDocument(undefined)}
      />
    </animated.div>
  );
};

export default ShowQuestionInfo;
