import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useLangs } from "../../../application/hooks/useLangs";
import { EventQuestionDto } from "../../../application/service/ApiClient";
import VoteQuestionsTable from "./VoteQuestionsTable";

type Props = {
  title: string;
  visible: boolean;
  questions?: EventQuestionDto[];
  onClose: () => void;
  onSubmit: (selectedQuestions: number[]) => void;
};

const VoteQuestionsFormDialog: React.FC<Props> = ({
  title,
  visible,
  questions,
  onClose,
  onSubmit,
}) => {
  const [selected, setSelected] = React.useState<number[]>([]);

  return (
    <>
      <Dialog open={visible} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <VoteQuestionsTable         
            questions={questions!}
            setSelectedIds={setSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style = {{color: "#004479"}}>{useLangs("CANCEL")}</Button>
          <Button onClick={() => onSubmit(selected)} style = {{color: "#004479"}}>{useLangs("RUN")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default VoteQuestionsFormDialog;
