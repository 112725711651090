import { Box } from "@mui/material";
import * as React from "react";
import onlineVoting from "../../../assets/images/icons/onlineVoting.svg";
import { useLangs } from "../../../application/hooks/useLangs";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/redux/store";
import {
  EventQuestionDto,
  EventQuestionStatus,
  ParticipantType,
} from "../../../application/service/ApiClient";
import VotingDetailsItem from "../../component/events/VotingDetailsItemComponent";
import { useNavigate } from "react-router";
import authService from "../../../application/service/AuthService";

const VotingDetailsPage = () => {
  const { event, absentParticipants, isVoting } = useSelector(
    (state: RootState) => state.showEvent
  );
  const navigation = useNavigate();

  const [questions, setQuestions] = React.useState<
    EventQuestionDto[] | undefined
  >([]);

  const LANG = {
    VOTING_DETAIL: useLangs("VOTING_DETAIL"),
  };

  React.useEffect(() => {
    if (event !== undefined) {
      const inProgressQuestions =
        event &&
        event.eventQuestions &&
        event.eventQuestions.filter(
          (x) => x.status === EventQuestionStatus.InProgress
        );
      setQuestions(inProgressQuestions);
    }
  }, [event]);

  React.useEffect(() => {
    if (!isVoting && authService.isCurrentUserAPanel()) {
      navigation("/events/live");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVoting]);

  return (
    <div>
      <Box
        sx={{
          typography: "div",
        }}
        className="pageHeader eventPageHeader"
      >
        <div>
          <div className="pageHeaderImg">
            <img alt="voting" src={onlineVoting} />
          </div>
          {LANG.VOTING_DETAIL}
        </div>
        <div />
      </Box>
      {questions && questions.length > 0 && (
        <VotingDetailsItem
          absentParticipants={absentParticipants}
          questions={questions}
          totalParticipants={
            event?.participants?.filter(
              (x) => x.participantType === ParticipantType.Delegate
            ).length!
          }
        />
      )}
    </div>
  );
};

export default VotingDetailsPage;
