import { DateTimePicker } from "@mui/lab";
import {
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FormikValues } from "formik";
import moment from "moment";
import React from "react";
import { useLangs } from "../../../application/hooks/useLangs";

interface Props {
  formik: FormikValues;
  isReadonly?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

var minDate = moment().subtract(0, "minutes");

const MainFormComponent: React.FC<Props> = ({ isReadonly, formik }) => {
  const LANG = {
    STARTING_DATE_AND_TIME: useLangs("STARTING_DATE_AND_TIME"),
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {isReadonly ? (
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label={LANG.STARTING_DATE_AND_TIME}
              value={formik.values.startDate ? formik.values.startDate : null}
              onChange={(newValue) =>
                formik.setFieldValue("startDate", newValue)
              }
              readOnly
            />
          ) : (
            <>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label={LANG.STARTING_DATE_AND_TIME}
                value={formik.values.startDate ? formik.values.startDate : null}
                onChange={(newValue) =>
                  formik.setFieldValue("startDate", newValue)
                }
                minDate={
                  formik.values.startDate
                    ? moment(formik.values.startDate)
                    : minDate
                }
              />
              <FormHelperText error>
                {formik.touched.startDate && formik.errors.startDate}
              </FormHelperText>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} mb={3}>
          <TextField
            name="title"
            maxRows={4}
            label={useLangs("NAME")}
            fullWidth
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputLabel>{useLangs("TYPE")}</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={formik.values.type}
            name="type"
            onChange={formik.handleChange}
            input={<Input />}
            MenuProps={MenuProps}
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <MenuItem key="za" value="za">
              {useLangs("MEETING_AKIMAT")}
            </MenuItem>
            <MenuItem key="zk" value="zk">
              {useLangs("LAND_COMMISSION")}
            </MenuItem>
            <MenuItem key="etc" value="etc">
              {useLangs("ANOTHER")}
            </MenuItem>
          </Select>
          <FormHelperText error>
            {formik.touched.type && formik.errors.type}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="description"
            maxRows={4}
            multiline
            label={useLangs("DESCRIPTION")}
            fullWidth
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainFormComponent;
