import React, { useReducer } from "react";
import routes from "./routes";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import theme from "./presentation/component/layout/Theme";
import GlobalStyles from "./presentation/GlobalStyles";
import { useRoutes } from "react-router-dom";
import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import "moment/locale/ru";
import "moment/locale/kk";
import "./assets/styles/table.css";
import "./assets/styles/main.css";
import "./assets/styles/tablet.css";
import mainReducer from "./reducers/mainReducer";
import { Context } from "./store/context";
import { initialState } from "./store/state";
import GlobalInit from "./presentation/GlobalInit";

const App = () => {
  const content = useRoutes(routes);
  const [state, dispatch] = useReducer(mainReducer, initialState);
  return (
    <>
      <Context.Provider value={{ dispatch, state }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              {content}
              <GlobalInit />
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </Context.Provider>
    </>
  );
};
export default App;
