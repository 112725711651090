import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button, ListItem } from "@mui/material";

type NavItemProps = {
  href: string;
  icon: React.ElementType;
  title: string;
  [x: string]: any;
};

const NavItem = ({ href, icon: Icon, title, ...rest }: NavItemProps) => {
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: "text.secondary",
          fontWeight: "medium",
          justifyContent: "flex-start",
          letterSpacing: 0,
          py: 1.25,
          textTransform: "none",
          width: "100%",
          //   ...(active && {
          //     color: "primary.main",
          //   }),
          "& svg": {
            mr: 1,
          },
        }}
        to={href}
      >
        {Icon && <Icon size="20" />}
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
