import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AccountClient,
  AppException,
  FileParameter,
  RolesClient,
  RoleVm,
} from "../../../../application/service/ApiClient";
import { useSnackbar } from "notistack";
import { useQuery } from "../../../../application/helpers/query";
import { useLangs } from "../../../../application/hooks/useLangs";
import Dropzone from "react-dropzone";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const validationSchema = Yup.object({
  fullName: Yup.string().required("Обязательное поле"),
  email: Yup.string().email("Нерпавильный email").required("Обязательное поле"),
  userName: Yup.string().required("Обязательное поле"),
  password: Yup.string().required("Установите пароль"),
});

const editValidationSchema = Yup.object({
  fullName: Yup.string().required("Обязательное поле"),
  email: Yup.string().email("Нерпавильный email").required("Обязательное поле"),
  userName: Yup.string().required("Обязательное поле"),
});

interface CreateEditAccountForm {
  id?: string | undefined;
  fullName?: string | undefined;
  group?: string | undefined;
  organization?: string | undefined;
  jobTitle?: string | undefined;
  email?: string | undefined;
  userName?: string | undefined;
  password?: string | undefined;
  roles?: string[] | undefined;
  language?: string | undefined;
  isInvited?: boolean;
  photo?: File;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUser = () => {
  const { id } = useParams();
  const history = useNavigate();
  const query = useQuery();

  const [roles, setRoles] = useState<RoleVm[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const LANG = {
    ATTACH_PHOTO: useLangs("ATTACH_PHOTO"),
    ALREADY_EXIST: useLangs("ALREADY_EXIST"),
    ERROR: useLangs("ERROR"),
  };

  const formik = useFormik<CreateEditAccountForm>({
    initialValues: {
      fullName: "",
      group: "", //change to dropdown
      organization: "",
      jobTitle: "",
      email: "", //email validator
      userName: "", // use surname
      password: "",
      roles: [], //user role
      language: "", // russian or kazakh,
      isInvited: query.get("invited") === "1",
      photo: undefined,
    },
    validationSchema: () => {
      if (id === undefined) {
        return validationSchema;
      }
      return editValidationSchema;
    },
    onSubmit: (values) =>
      id !== undefined ? handleEdit(values) : handleAdd(values),
  });

  const handleEdit = async (values: CreateEditAccountForm) => {
    try {
      const accountsClient = new AccountClient();
      const photo: FileParameter | undefined =
        values.photo === undefined
          ? undefined
          : { data: values.photo, fileName: values.photo.name };

      await accountsClient.editAccount(
        id,
        values.fullName,
        values.group,
        values.organization,
        values.jobTitle,
        values.email,
        values.userName,
        values.password,
        values.roles,
        values.language,
        values.isInvited,
        photo
      );
      enqueueSnackbar("Аккаунт успешно обновлен", { variant: "success" });
      history("/settings");
    } catch (e) {
      if (e instanceof AppException) {
        const error = JSON.parse(e.response);
        if (error.detail === "ALREADY_EXIST") {
          enqueueSnackbar(LANG.ALREADY_EXIST, { variant: "error" });
        }
      } else {
        enqueueSnackbar(LANG.ERROR, { variant: "error" });
      }
    }
  };

  const handleAdd = async (values: CreateEditAccountForm) => {
    try {
      const accountsClient = new AccountClient();
      const photo: FileParameter | undefined =
        values.photo === undefined
          ? undefined
          : { data: values.photo, fileName: values.photo.name };
      await accountsClient.create(
        values.fullName,
        values.group,
        values.organization,
        values.jobTitle,
        values.email,
        values.userName,
        values.password,
        values.roles,
        values.language,
        values.isInvited,
        photo
      );
      enqueueSnackbar("Аккаунт успешно создан", { variant: "success" });
      history("/settings");
    } catch (e) {
      console.error(e);
      enqueueSnackbar(LANG.ERROR, { variant: "error" });
    }
  };

  const loadUser = async () => {
    try {
      const accountsClient = new AccountClient();

      const account = await accountsClient.getAccount(id as string);
      formik.setValues({ ...account });
    } catch (e) {
      console.error(e);
    }
  };

  const loadRoles = async () => {
    try {
      const rolesClient = new RolesClient();
      const roles = await rolesClient.getRoles();
      setRoles(roles);
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Ошибка", { variant: "error" });
    }
  };

  const handleDropQuestionFile = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    console.log(acceptedFiles);
    formik.setFieldValue("photo", acceptedFiles[0]);
  };

  // const deleteQuestionFile = (index: number) => {
  //   console.log(index);
  //   // let newQuestionFiles = questionFiles.filter((el, i) => i !== index);
  //   // setQuestionFiles([...newQuestionFiles]);
  // };
  useEffect(() => {
    loadRoles();
    if (id !== undefined) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Редактировать пользователя" />
        <Divider />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Input type="hidden" name="IsInvited" />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="fullName"
                  label="ФИО"
                  fullWidth
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="group"
                  label="Группа"
                  fullWidth
                  value={formik.values.group}
                  onChange={formik.handleChange}
                  error={formik.touched.group && Boolean(formik.errors.group)}
                  helperText={formik.touched.group && formik.errors.group}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="organization"
                  label="Организация"
                  fullWidth
                  value={formik.values.organization}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.organization &&
                    Boolean(formik.errors.organization)
                  }
                  helperText={
                    formik.touched.organization && formik.errors.organization
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="jobTitle"
                  label="Должность"
                  fullWidth
                  value={formik.values.jobTitle}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                  }
                  helperText={formik.touched.jobTitle && formik.errors.jobTitle}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  type="email"
                  name="email"
                  label="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12}>
            <TextField
              name="cardNumber"
              maxRows={4}
              multiline
              label="Номер карты"
              fullWidth
              value={formik.values.cardNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
              }
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}
            />
          </Grid> */}

              <Grid item xs={12} sm={12}>
                <TextField
                  name="userName"
                  label="Логин"
                  fullWidth
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  type="password"
                  name="password"
                  label="Пароль"
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputLabel>Роль</InputLabel>
                <Select
                  multiple
                  style={{ width: "100%" }}
                  value={formik.values.roles}
                  name="roles"
                  onChange={formik.handleChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputLabel>Язык</InputLabel>
                <Select
                  style={{ width: "100%" }}
                  value={formik.values.language}
                  name="language"
                  onChange={formik.handleChange}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="kz" value="kz">
                    Казахский
                  </MenuItem>
                  <MenuItem key="ru" value="ru">
                    Русский
                  </MenuItem>
                  {/* <MenuItem key="en" value="en">
                    Английский
                  </MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Dropzone accept="image/*" onDrop={handleDropQuestionFile}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button component="label">
                          <AttachFileIcon />
                          {LANG.ATTACH_PHOTO}
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {formik.values &&
                  formik.values.photo &&
                  formik.values.photo.name}
              </Grid>
              <Grid
                xs={12}
                sm={12}
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className="defaultBtn mainBtn"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={() => history("/settings")}
                  className="defaultBtn"
                >
                  {useLangs("CANCEL")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AddUser;
