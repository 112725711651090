import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/redux/store";
import { ParticipantType } from "../../../application/service/ApiClient";

const Quorum = () => {
  const { quorumCounter, event } = useSelector(
    (state: RootState) => state.showEvent
  );

  if (event === undefined || event === null) {
    return <></>;
  }

  const total =
    event?.participants?.filter(
      (x) => x.participantType === ParticipantType.Delegate
    ).length || 0;

  const half = total / 2;

  return (
    <div
      className={`quorumWrap ${
        quorumCounter > half ? "quorumQreenWrap" : "quorumRedWrap"
      }`}
    >
      Кворум {total < quorumCounter ? total : quorumCounter}/{total}
    </div>
  );
};

export default Quorum;
