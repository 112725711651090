import makeStyles from "@mui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { Box, Card, CardContent, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import {
  VoteChoice,
  VotesClient,
} from "../../../application/service/ApiClient";
import { useDispatch, useSelector } from "react-redux";
import { useLangs } from "../../../application/hooks/useLangs";
import { CountDownTimer } from "../../component/live/TimerComponent";
import {
  loadQuestionsToVote,
  setVoteModalVisible,
} from "../../../application/slices/agendaSlice";
import { RootState } from "../../../application/redux/store";

const useStyles = makeStyles({
  button1: {
    height: "200px",
    width: "200px",
    justifyContent: "center",
    background: "white",
    color: "green",
    borderRadius: "50%",
    border: "13px solid",
    marginBottom: "10px",
  },

  button2: {
    height: "200px",
    width: "200px",
    justifyContent: "center",
    background: "white",
    color: "red",
    borderRadius: "50%",
    border: "13px solid",
    marginBottom: "10px",
  },

  button3: {
    color: "black",
    font: "bold",
    fontSize: "25px",
  },

  button4: {
    color: "black",
    font: "bold",
    fontSize: "25px",
  },

  check: {
    height: "50%",
    width: "50%",
    justifyContent: "center",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  font: {
    fontStyle: "bold",
    color: "black",
    boxShadow: "none",
    fontSize: "14",
  },

  grid: {
    marginTop: "-23px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

type Props = {
  eventId: number;
};

const VotingPage: React.FC<Props> = ({ eventId }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { questions } = useSelector((state: RootState) => state.agenda);
  const [isTimeOver, setIsTimeOver] = React.useState(false);

  const handleAction = async (choice: VoteChoice) => {
    try {
      const questionIds = questions.map((x) => x.id!);
      const votesClient = new VotesClient();
      await votesClient.addVote({
        choice,
        questionId: questionIds,
      });
      dispatch(setVoteModalVisible(false));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(loadQuestionsToVote(eventId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const hoursMinSecs = { hours: 1, minutes: 20, seconds: 15 };

  return (
    <Card style={{ height: "100%" }}>
      <Box
        style={{
          display: "flex-end",
          justifyContent: "end",
          alignItems: "center",
          margin: "10px",
        }}
        className={
          isTimeOver ? "detailedHeaderStatusRed" : "detailedHeaderStatusGreen"
        }
      >
        <CountDownTimer
          setIsTimeOver={setIsTimeOver}
          hoursMinSecs={hoursMinSecs}
        />{" "}
      </Box>
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2 }}>
            {questions.map((question) => (
              <Grid
                className="voteQuestionsItem"
                item
                xs={12}
                key={question.id}
              >
                <Item className={classes.font}>
                  {question.index}. {question.title}
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
      <CardContent className={classes.center}>
        <Grid>
          <>
            <div
              style={{
                display: "flex",
                width: "600px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => handleAction(VoteChoice.Accept)}
                  className={classes.button1}
                >
                  <CheckIcon className={classes.check} />
                </Button>
                <Button
                  onClick={() => handleAction(VoteChoice.Accept)}
                  className={classes.button3}
                >
                  {useLangs("PER")}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => handleAction(VoteChoice.Decline)}
                  className={classes.button2}
                >
                  <CloseIcon className={classes.check} />
                </Button>
                <Button
                  onClick={() => handleAction(VoteChoice.Decline)}
                  className={classes.button4}
                >
                  {useLangs("AGAINST")}
                </Button>
              </div>
            </div>
          </>
        </Grid>
      </CardContent>
      <CardContent className={classes.center}>
        <Grid className={classes.grid}>
          <></>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VotingPage;
