import React from "react";
import { RouteObject } from "react-router";
import Home from "./presentation/container/home/HomeContainer";
import AddEditRole from "./presentation/container/settings/roles/AddEditRoleContainer";
import Settings from "./presentation/container/settings/SettingsContainer";
import AddUser from "./presentation/container/settings/users/AddUserContainer";
import InvitedUsers from "./presentation/container/settings/users/InvitedUserContainer";
import Events from "./presentation/container/events/EventsContainer";
import AddEditEvent from "./presentation/container/events/AddEditEventContainer";
import ShowEvent from "./presentation/container/live/ShowEventContainer";
import MainLayout from "./presentation/component/layout/MainLayout";
import Login from "./presentation/container/login/LoginContainer";
import DashboardLayout from "./presentation/component/layout/DashboardLayout";
import AgendaContainer from "./presentation/container/live/AgendaContainer";
import VotingDetailsContainer from "./presentation/container/live/VotingDetailsContainer";
import CreateEvent from "./presentation/container/events/CreateEventContainer";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        element: <Home />,
        path: "",
      },
      {
        element: <Settings />,
        path: "settings",
      },
      {
        element: <Events />,
        path: "events",
      },
      { element: <AddEditRole />, path: "roles/add" },
      { element: <AddEditRole />, path: "roles/edit/:id" },
      { element: <AddUser />, path: "users/add" },
      { element: <AddUser />, path: "users/edit/:id" },
      { element: <InvitedUsers />, path: "users/invited" },
      { element: <CreateEvent />, path: "events/add" },
      { element: <AddEditEvent />, path: "events/card/:id" },
      { element: <ShowEvent />, path: "events/live" },
      { element: <AgendaContainer />, path: "agenda" },
      { element: <VotingDetailsContainer />, path: "voting/details" },
    ],
  },
  {
    path: "/account",
    element: <MainLayout />,
    children: [{ path: "login", element: <Login /> }],
  },
];

export default routes;
