import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useLangs } from "../../../application/hooks/useLangs";
import {
  ActiveEventVm,
  EventQuestionDto,
  EventQuestionStatus,
  ParticipantType,
  VoteChoice,
} from "../../../application/service/ApiClient";

type Props = {
  event: ActiveEventVm;
  question: EventQuestionDto;
  index: number;
};

const ShowLiveEventQuestion: React.FC<Props> = ({ index, event, question }) => {
  const LANG = {
    NO_EVENTS: useLangs("NO_EVENTS"),
    VOTING_IN_PROGRESS: useLangs("VOTING_IN_PROGRESS"),
    NEXT_EVENT: useLangs("NEXT_EVENT"),
    STOP: useLangs("STOP"),
    RESTART: useLangs("RESTART"),
    CANCEL: useLangs("CANCEL"),
    VOTING: useLangs("VOTING"),
    ACCEPTED: useLangs("ACCEPTED"),
    DECLINED: useLangs("DECLINED"),
    NOT_STARTED: useLangs("NOT_STARTED"),
    LAUNCH_EVENT: useLangs("LAUNCH_EVENT"),
    LAUNCH: useLangs("LAUNCH"),
    RESTART_EVENT: useLangs("RESTART_EVENT"),
    MEETING_AKIMAT: useLangs("MEETING_AKIMAT"),
    STOP_EVENT: useLangs("STOP_EVENT"),
    CONFIRM: useLangs("CONFIRM"),
    WANT_TO_STOP_EVENT: useLangs("WANT_TO_STOP_EVENT"),
  };

  const totalParticipants = event?.participants?.filter(
    (x) => x.participantType === ParticipantType.Delegate
  ).length;

  const calculateQuestionResult = (
    event: ActiveEventVm,
    question: EventQuestionDto
  ): boolean => {
    const acceptedVotes =
      question && question.votes?.filter((x) => x.choice === VoteChoice.Accept);

    const declinedVotes =
      question &&
      question.votes?.filter((x) => x.choice === VoteChoice.Decline);

    const acceptedPercentage = Math.floor(
      ((acceptedVotes?.length ?? 0 * 100) / totalParticipants!) * 100
    );

    const declinedPercentage = Math.floor(
      ((declinedVotes?.length ?? 0 * 100) / totalParticipants!) * 100
    );

    if (declinedPercentage === acceptedPercentage) {
      const castingUsers =
        event.participants?.filter((x) => x.hasCastingVote) || [];
      if (castingUsers.length > 0) {
        const castingUserResponse =
          question.votes?.filter((x) => x.userId === castingUsers[0].userId) ||
          [];
        if (castingUserResponse.length > 0) {
          const choice = castingUserResponse[0].choice;
          switch (choice) {
            case VoteChoice.Accept:
              return true;
            case VoteChoice.Decline:
            case VoteChoice.Absent:
              return false;
          }
        }
      }
    }

    return acceptedPercentage > 50;
  };
  const result = calculateQuestionResult(event, question);
  let status = null;
  let bgColor = "#f7f7f7";
  switch (question.status) {
    case EventQuestionStatus.NotStarted:
      status = (
        <div>
          <p>{LANG.VOTING}:</p>{" "}
          <p className="eventsVotingNotStarted">{LANG.NOT_STARTED}</p>
        </div>
      );
      bgColor = "#f7f7f7";
      break;
    case EventQuestionStatus.InProgress:
      status = (
        <div>
          <p>{LANG.VOTING}:</p> <p>{LANG.VOTING_IN_PROGRESS}</p>
        </div>
      );
      bgColor = "#d4d1d1";

      break;
    case EventQuestionStatus.Completed:
      status = (
        <div>
          <p>{LANG.VOTING}:</p>{" "}
          <p>
            {result ? (
              <Box style={{ color: "green", fontWeight: "bold" }}>
                {LANG.ACCEPTED}
              </Box>
            ) : (
              <Box style={{ color: "red", fontWeight: "bold" }}>
                {LANG.DECLINED}
              </Box>
            )}
          </p>
        </div>
      );
      bgColor = result ? "#d3ebd3" : "#e7c0c0";
      break;
  }

  return (
    <Grid item style={{ padding: 10 }} key={question.id}>
      <Card
        sx={{ backgroundColor: bgColor, minWidth: 275, padding: 10 }}
        className="eventsShowCard"
      >
        <CardContent>
          <Typography
            sx={{
              fontSize: 16,
              color: "#3F6AB8",
              fontWeight: "bold",
            }}
            className="eventsShowHeader"
            title={question.title}
          >
            {index + 1}. {question.title}
          </Typography>
          <Box className="eventStatics">
            <Box className="eventsStaticsGreen">{question.acceptedCount}</Box>
            <Box className="eventsStaticsRed">{question.declinedCount}</Box>
          </Box>
          <div className="eventsReview">
            {status}
            <div className="eventsReviewDivider"></div>
            <div>
              <p>{question.proposedBy ?? "-"}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ShowLiveEventQuestion;
