import {
  AccountClient,
  EventQuestionAttachmentsClient,
  EventProtocolTemplatesClient,
  EventsClient,
  ParticipantsClient,
  QuestionsClient,
} from "./ApiClient";

const accountsService = new AccountClient();
const eventsService = new EventsClient();
const questionsService = new QuestionsClient();
const participantsService = new ParticipantsClient();
const eventQuestionAttachmentService = new EventQuestionAttachmentsClient();
const protocolTemplateService = new EventProtocolTemplatesClient();

export {
  accountsService,
  eventsService,
  questionsService,
  participantsService,
  eventQuestionAttachmentService,
  protocolTemplateService,
};
