import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import "./ShowEventContainer.css";
import cowerking from "../../../assets/images/icons/coworking.svg";
import {
  ActionType,
  EventQuestionStatus,
  EventsClient,
  VoteActionType,
  VotesClient,
} from "../../../application/service/ApiClient";
import VoteQuestionsFormDialog from "../../component/events/VoteQuestionsFormDialog";
import authService, {
  ClaimCode,
} from "../../../application/service/AuthService";
import { RootState } from "../../../application/redux/store";
import {
  changeIsVoting,
  changeQuestionPositions,
  loadShowEventPageEventAsync,
  loadUpcomingEventDates,
} from "../../../application/slices/showEventSlice";
import { useLangs } from "../../../application/hooks/useLangs";
import { CountUpTimer } from "../../component/live/TimerComponent";
import { Context } from "../../../store/context";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../application/redux/hooks";
import { useNavigate } from "react-router";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";
import ShowLiveEventQuestion from "../../component/live/ShowLiveEventQuestion";
import { questionsService } from "../../../application/service";

const ShowEvent = () => {
  const dispatch = useAppDispatch();
  const { event, loading, isVoting, upcomingEventDates } = useAppSelector(
    (state: RootState) => state.showEvent
  );
  const [showStartModalVisible, setShowStartModalVisible] = useState(false);
  const [showRestartModalVisible, setShowRestartModalVisible] = useState(false);
  const user: any = authService.getUser();
  const navigation = useNavigate();

  const handlePositionChange = async (
    sourceId: any,
    sourceIndex: number,
    targetIndex: number,
    targetId: any
  ) => {
    const nextState = swap(event!.eventQuestions!, sourceIndex, targetIndex);
    dispatch(changeQuestionPositions(nextState));
    const newQuestionPositionsId = nextState.map((x) => x.id!);
    try {
      await questionsService.updateQuestionsOrderNumber({
        eventId: event?.id,
        eventQuestionIdList: newQuestionPositionsId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleRunVoting = async (selectedQuestions: number[]) => {
    setShowStartModalVisible(false);
    try {
      const votesClient = new VotesClient();
      await votesClient.applyAction({
        actionType: VoteActionType.StartVoting,
        questionIds: selectedQuestions,
      });
      dispatch(changeIsVoting(true));
    } catch (e) {
      console.error(e);
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCompleteVoting = async () => {
    const startedQuestionsIds = event?.eventQuestions
      ?.filter((x) => x.status === EventQuestionStatus.InProgress)
      .map((x) => x.id);

    try {
      const votesClient = new VotesClient();
      await votesClient.applyAction({
        actionType: VoteActionType.CompleteVoting,
        questionIds: startedQuestionsIds as number[],
      });
      dispatch(changeIsVoting(false));
    } catch (e) {
      console.error(e);
    }
  };

  const handleStopEvent = async (action: ActionType) => {
    const eventsClient = new EventsClient();
    try {
      const applyActionCommand = {
        id: event?.id,
        action,
      };
      await eventsClient.applyAction(applyActionCommand);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRestartVoting = async (selectedQuestions: number[]) => {
    setShowRestartModalVisible(false);
    try {
      const votesClient = new VotesClient();
      await votesClient.applyAction({
        actionType: VoteActionType.RestartVoting,
        questionIds: selectedQuestions,
      });
      dispatch(changeIsVoting(true));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (event === null || event === undefined) {
      dispatch(loadShowEventPageEventAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LANG = {
    NO_EVENTS: useLangs("NO_EVENTS"),
    VOTING_IN_PROGRESS: useLangs("VOTING_IN_PROGRESS"),
    NEXT_EVENT: useLangs("NEXT_EVENT"),
    STOP: useLangs("STOP"),
    RESTART: useLangs("RESTART"),
    CANCEL: useLangs("CANCEL"),
    VOTING: useLangs("VOTING"),
    ACCEPTED: useLangs("ACCEPTED"),
    DECLINED: useLangs("DECLINED"),
    NOT_STARTED: useLangs("NOT_STARTED"),
    LAUNCH_EVENT: useLangs("LAUNCH_EVENT"),
    LAUNCH: useLangs("LAUNCH"),
    RESTART_EVENT: useLangs("RESTART_EVENT"),
    MEETING_AKIMAT: useLangs("MEETING_AKIMAT"),
    STOP_EVENT: useLangs("STOP_EVENT"),
    CONFIRM: useLangs("CONFIRM"),
    WANT_TO_STOP_EVENT: useLangs("WANT_TO_STOP_EVENT"),
  };

  const ANOTHER = useLangs("ANOTHER");
  const MEETING_AKIMAT = useLangs("MEETING_AKIMAT");
  const LAND_COMMISSION = useLangs("LAND_COMMISSION");

  const {
    state: { lang },
  } = useContext(Context);

  useEffect(() => {
    if (event === undefined || event === null) {
      dispatch(loadUpcomingEventDates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  useEffect(() => {
    if (isVoting && authService.isCurrentUserAPanel()) {
      navigation("/voting/details");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVoting]);

  if (loading) {
    return <CircularProgress />;
  }

  const momentLocalLang = {
    KZ: "kk",
    RU: "ru",
  };

  const changeMoment = () => {
    if (momentLocalLang[lang] === "ru") {
      return "LLL";
    } else return "D MMMM YYYY ж., сағ. HH:mm жоспарланған";
  };

  const notStartedQuestions =
    event &&
    event.eventQuestions &&
    event.eventQuestions.filter(
      (x) => x.status === EventQuestionStatus.NotStarted
    );

  const canStart = notStartedQuestions && notStartedQuestions.length > 0;

  const completedQuestions =
    event &&
    event.eventQuestions &&
    event.eventQuestions.filter(
      (x) => x.status === EventQuestionStatus.Completed
    );

  const canRestart = completedQuestions && completedQuestions.length > 0;

  const stopEvent = () => {
    handleOpen();
  };

  const dropzoneHeight =
    Math.ceil((event?.eventQuestions?.length || 0) / 4) * 260;

  return event === undefined ? (
    upcomingEventDates.length === 0 ? (
      <h1 style={{ textAlign: "center", color: "#004479", marginTop: "18%" }}>
        {LANG.NO_EVENTS}
      </h1>
    ) : (
      <h1 style={{ textAlign: "center", color: "#004479", marginTop: "16%" }}>
        {LANG.NEXT_EVENT}{" "}
        <p>
          {moment(upcomingEventDates[0])
            .locale(momentLocalLang[lang])
            .format(changeMoment())}
        </p>
      </h1>
    )
  ) : (
    <React.Fragment>
      <Box
        sx={{
          typography: "div",
        }}
        className="pageHeader eventPageHeader"
      >
        <div>
          <div className="pageHeaderImg">
            <img alt="voting" src={cowerking} />
          </div>
          {event.type === "za"
            ? MEETING_AKIMAT
            : event.type === "zk"
            ? LAND_COMMISSION
            : event.type === "etc"
            ? ANOTHER
            : null}
        </div>
        <div>
          <CountUpTimer event={event} />
          {user.Permission.some(
            (x: string) => x === ClaimCode.VotingActions
          ) && (
            <>
              <Button
                className={
                  isVoting
                    ? "defaultBtn"
                    : canStart
                    ? "defaultBtn mainBtn"
                    : "defaultBtn"
                }
                disabled={isVoting || !canStart}
                onClick={() => setShowStartModalVisible(true)}
              >
                {LANG.LAUNCH}
              </Button>
              <Button
                className={isVoting ? "defaultBtn mainBtn" : "defaultBtn"}
                disabled={!isVoting}
                onClick={handleCompleteVoting}
              >
                {LANG.STOP}
              </Button>
              <Button
                className={
                  isVoting
                    ? "defaultBtn"
                    : canRestart
                    ? "defaultBtn mainBtn"
                    : "defaultBtn"
                }
                disabled={isVoting || !canRestart}
                onClick={() => setShowRestartModalVisible(true)}
              >
                {LANG.RESTART}
              </Button>
              <Button
                disabled={event === undefined || event === null}
                className="defaultBtn"
              >
                {LANG.CANCEL}
              </Button>
              <Button
                disabled={event === undefined || event === null}
                className={`defaultBtn ${
                  event === undefined || event === null ? "" : "mainBtn"
                }`}
                onClick={() => stopEvent()}
              >
                {LANG.STOP_EVENT}
              </Button>
            </>
          )}
        </div>
      </Box>
      <div className="eventsShowContent">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {user.Permission.some(
            (x: string) => x === ClaimCode.VotingActions
          ) ? (
            <GridContextProvider onChange={handlePositionChange}>
              <GridDropZone
                id="eventQuestions"
                boxesPerRow={4}
                rowHeight={250}
                className={`dropzone`}
                style={{ height: dropzoneHeight }}
              >
                {event!.eventQuestions!.map((question, index) => (
                  <GridItem key={question.id}>
                    <ShowLiveEventQuestion
                      question={question}
                      index={index}
                      event={event}
                    />
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
          ) : (
            <>
              {event!.eventQuestions!.map((question, index) => (
                <div className="panelCards">
                  <ShowLiveEventQuestion
                    question={question}
                    index={index}
                    event={event}
                  />
                </div>
              ))}
            </>
          )}
        </Grid>
        <VoteQuestionsFormDialog
          visible={showStartModalVisible}
          title={LANG.LAUNCH_EVENT}
          onClose={() => setShowStartModalVisible(false)}
          questions={notStartedQuestions}
          onSubmit={handleRunVoting}
        />
        <VoteQuestionsFormDialog
          visible={showRestartModalVisible}
          title={LANG.RESTART_EVENT}
          onClose={() => setShowRestartModalVisible(false)}
          questions={completedQuestions}
          onSubmit={handleRestartVoting}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {LANG.WANT_TO_STOP_EVENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#004479" }}
            onClick={() => handleStopEvent(ActionType.Finish)}
          >
            {LANG.CONFIRM}
          </Button>
          <Button style={{ color: "#004479" }} onClick={handleClose}>
            {LANG.CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ShowEvent;
