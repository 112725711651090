import { Box } from "@material-ui/system";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import timeIcon from "../../../assets/images/icons/time.svg";
import { Context } from "../../../store/context";

const Logo = (props: any) => {
  const date = new Date();
  const [time, setTime] = useState("");
  const { state: { lang } } = useContext(Context)
  useEffect(() => {
    const intervalIDValue = setInterval(() => tick(), 1000);
    return () => clearInterval(intervalIDValue);
  }, []);

  const tick = () => {
    const currentTime = new Date().toLocaleTimeString();
    setTime(currentTime);
  };

  const momentLocalLang = {
    KZ: "kk",
    RU: "ru"
  }

  return (
    <Box className="navbarTime">
      <img alt="Logo" src={timeIcon} />
      <Box>
        <p style={{color: "#004479"}} >{time}</p>
        <p style={{color: "#004479"}}>{moment(date).locale(momentLocalLang[lang]).format("LL")}</p>
      </Box>
    </Box>
  );
};

export default Logo;
