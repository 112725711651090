import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Select,
  Input,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Eyecon from "@mui/icons-material/RemoveRedEye";
import makeStyles from "@mui/styles/makeStyles";
import {
  EventsClient,
  EventStatusType,
  EventStatus,
  EventVm,
} from "../../../application/service/ApiClient";

import { useSnackbar } from "notistack";
import { useLangs } from "../../../application/hooks/useLangs";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    height: 400,
    width: "100%",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Events = () => {
  const history = useNavigate();
  const classes = useStyles();
  const [events, setEvents] = React.useState<EventVm[]>([]);
  const eventsClient = new EventsClient();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [totalRows, setTotalRows] = useState(5);
  const [eventsStatus, setEventsStatus] = useState(EventStatusType.All);
  const [open, setOpen] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState<number | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = (id: number | undefined) => {
    setDeleteEventId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setDeleteEventId(undefined);
    setOpen(false);
  };

  const CREATED = useLangs("CREATED");
  const ARCHIVED = useLangs("ARCHIVED");
  const COMPLETED_STATUS = useLangs("COMPLETED_STATUS");
  const IN_PROGRESS = useLangs("IN_PROGRESS");
  const PUBLISHED = useLangs("PUBLISHED");
  const DELETED = useLangs("DELETED");

  const EventStatusName = {
    [EventStatus.Created]: CREATED,
    [EventStatus.Archived]: ARCHIVED,
    [EventStatus.Completed]: COMPLETED_STATUS,
    [EventStatus.Deleted]: DELETED,
    [EventStatus.InProgress]: IN_PROGRESS,
    [EventStatus.Published]: PUBLISHED,
  };

  const loadEvents = async () => {
    try {
      setLoading(true);
      const events = await eventsClient.getEvents(eventsStatus, page, pageSize);
      setTotalRows(events.totalCount || 5);
      setPage(events.pageIndex || page);
      setEvents(events?.items || []);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const deleteEvent = async () => {
    try {
      const deleteEventCommand = { id: deleteEventId };
      await eventsClient.delete(deleteEventCommand);
      handleClose();
      enqueueSnackbar("Мероприятие успешно удалено", { variant: "success" });
      loadEvents();
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Ошибка", { variant: "error" });
    }
  };

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, eventsStatus]);
  const NAME = useLangs("NAME");
  const STATUS = useLangs("STATUS");
  const STARTING_DATE_AND_TIME = useLangs("STARTING_DATE_AND_TIME");
  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: NAME,
      width: 350,
    },
    {
      type: "number",
      field: "status",
      headerName: STATUS,
      width: 200,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => {
        const valueFormatted: EventStatus = Number(params.value);
        return EventStatusName[valueFormatted];
      },
    },
    {
      field: "startDate",
      headerName: STARTING_DATE_AND_TIME,
      width: 300,
      type: "date",
      valueFormatter: (params) => {
        let value: Date;
        if (typeof params.value == "string") {
          value = new Date(params.value);
          return moment(value).format("DD-MM-YYYY HH:mm");
        }
      },
    },
    {
      field: "id",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <IconButton
              onClick={() => history(`/events/card/${params.row.id}`)}
              size="large"
            >
              <Eyecon />
            </IconButton>
            <IconButton
              onClick={() => handleOpen(Number(params.row.id) || undefined)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <Card>
        <CardHeader title={useLangs("EVENTS_LIST")} />
        <Divider />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Select
              style={{ width: "20%", marginRight: "20px" }}
              value={eventsStatus}
              name="status"
              onChange={(e) =>
                setEventsStatus(Number(e.target.value) || EventStatusType.All)
              }
              input={<Input />}
              MenuProps={MenuProps}
            >
              <MenuItem value={EventStatusType.All}>{useLangs("ALL")}</MenuItem>
              <MenuItem value={EventStatusType.ActiveOnly}>
                {useLangs("ACTIVE_ONLY")}
              </MenuItem>
              <MenuItem value={EventStatusType.Completed}>
                {useLangs("COMPLETED")}
              </MenuItem>
            </Select>
            <Button
              variant="contained"
              onClick={() => history("/events/add")}
              className="mainBtn"
            >
              {useLangs("ADD_EVENT")}
            </Button>
          </Grid>

          <br />
          <div className={classes.table}>
            <DataGrid
              page={page - 1}
              rows={events}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              pagination
              paginationMode="server"
              loading={loading}
              rowCount={totalRows}
              rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageChange={(newPage) => {
                setPage(newPage + 1);
              }}
            />
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {useLangs("YOU_WANT_DELETE_EVENT")}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004479" }} onClick={deleteEvent}>
            {useLangs("DELETE")}
          </Button>
          <Button style={{ color: "#004479" }} onClick={handleClose}>
            {useLangs("CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Events;
