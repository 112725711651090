import moment from "moment";
import React, { useEffect, useState } from "react";
import { EventDetailVm } from "../../../application/service/ApiClient";

interface ICountDownTimer {
  hoursMinSecs: any;
  setIsTimeOver: (value: boolean) => void;
}

export const CountDownTimer = ({
  hoursMinSecs,
  setIsTimeOver,
}: ICountDownTimer) => {
  const { seconds = 15 } = hoursMinSecs;
  const [secs, setTime] = useState(seconds);

  const tick = () => {
    if (secs === 0) {
      setTime(0);
    } else if (secs === 6) {
      setIsTimeOver(true);
      setTime(secs - 1);
    } else if (secs === 0) {
      setTime(59);
    } else {
      setTime(secs - 1);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      <p>{`00:${secs.toString().padStart(2, "0")}`}</p>
    </div>
  );
};

type Props = {
  event?: EventDetailVm;
};

export const CountUpTimer: React.FC<Props> = ({ event }) => {
  const [time, setTime] = useState("");

  useEffect(() => {
    const intervalIDValue = setInterval(
      () => tick(event && event.launchDate),
      1000
    );
    return () => clearInterval(intervalIDValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tick = async (eventLaunchDate?: Date) => {
    const currentTime = new Date().getTime();
    var a = moment(currentTime);
    var b = moment(eventLaunchDate);
    const duration = a.diff(b);
    var d = new Date(duration).toISOString().substr(11, 8);
    setTime(d);
  };

  return <span style={{ color: "#004479", marginRight: "15px" }}>{time}</span>;
};
