import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { RolesClient, RoleVm } from "../../../../application/service/ApiClient";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useLangs } from "../../../../application/hooks/useLangs";

const useStyles = makeStyles({
  table: {
    height: 400,
    width: "100%",
  },
});

const Roles = () => {
  const classes = useStyles();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = useState<RoleVm[]>([]);
  const [loading, setLoading] = useState(false);
  const rolesClient = new RolesClient();
  const [open, setOpen] = useState(false);
  const [deleteAccountId, setDeleteAccountId] = useState<string | undefined>(
    undefined
  );
  const handleOpen = (id: string | undefined) => {
    setDeleteAccountId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteAccountId(undefined);
    setOpen(false);
  };
  
  const ACCOUNT_SUCCESSFULLY_DELETED = useLangs("ACCOUNT_SUCCESSFULLY_DELETED");
  const NAME = useLangs("NAMES");
  const DESCRIPTION = useLangs("DESCRIPTION");
  const WANT_TO_DELETE = useLangs("WANT_TO_DELETE");
  const CONFIRM = useLangs("CONFIRM");
  const CANCEL = useLangs("CANCEL");
 
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: NAME,
      width: 400,
    },
    {
      field: "description",
      headerName: DESCRIPTION,
      width: 500,
    },
    {
      field: "id",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <IconButton
              onClick={() => history(`/roles/edit/${params.row.id}`)}
              size="large"
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleOpen(String(params.row.id) || undefined)} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const deleteRole = async () => {
    try {
      setLoading(true);
      const command = { id: deleteAccountId };
      await rolesClient.deleteRole(command);
      handleClose();
      enqueueSnackbar(ACCOUNT_SUCCESSFULLY_DELETED, { variant: "error" });
      loadRoles();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const loadRoles = async () => {
    try {
      setLoading(true);
      const roles = await rolesClient.getRoles();
      setRoles(roles);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="contained"
          onClick={() => history("/roles/add")}
          color="primary"
          className="defaultBtn mainBtn"
        >
          {useLangs("ADD")}
        </Button>
      </Grid>
      <br />
      <div className={classes.table}>
        <DataGrid
          rows={roles}
          columns={columns}
          loading={loading}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {WANT_TO_DELETE}
            </DialogContentText>
          </DialogContent>
            <DialogActions>
              <Button style={{ color: "#004479" }} onClick={deleteRole}>
                {CONFIRM}
              </Button>
              <Button style={{ color: "#004479" }} onClick={handleClose}>{CANCEL}</Button>
            </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Roles;
