export const removeTimeZone = (dateValue?: Date) => {
  if (dateValue === undefined) {
    return undefined;
  }
  var targetTime = new Date(dateValue);
  var timeZoneFromDB = 12;
  var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
  dateValue = new Date(targetTime.getTime() + tzDifference * 60 * 1000);

  return dateValue;
};
