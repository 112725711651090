import { IReducerState } from './../reducers/mainReducer';
import authService from "../application/service/AuthService";

enum userLangEnum  {
    kz = "KZ",
    ru = "RU"
}

export const initialState: IReducerState = {
    lang: userLangEnum[authService.getLang()]
}