import { ILangItems } from "../../data/langs";
import { Context } from "../../store/context";
import { useContext } from "react";
import { LANGS } from "../../data/langs";

export const useLangs = (value: ILangItems) => {
  const {
    state: { lang },
  } = useContext(Context);
  return LANGS[lang][value];
};
