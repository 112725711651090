import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert, Box, Card, CardContent, Divider } from "@mui/material";
import { AccountClient } from "../../../application/service/ApiClient";
import { object, string } from "yup";
import { useFormik } from "formik";
import authService from "../../../application/service/AuthService";

const validationSchema = object({
  userName: string().required("Обязательное поле"),
  password: string().required("Обязательное поле"),
});

const Login = () => {
  const accountClient = new AccountClient();

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setErrorVisible(false);
      try {
        const token = await accountClient.login(values);
        authService.login(token);
        const tokenData = authService.getUser()
        const userData = await accountClient.getAccount(tokenData.userId)
        authService.setLang(userData.language || "")
        window.location.replace("/");
      } catch (e) {
        console.error(e);
        setErrorVisible(true);
      }
    },
  });
  const [isErrorVisible, setErrorVisible] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Авторизация
                  </Typography>
                </Box>
                <Divider />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Логин"
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Пароль"
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  autoComplete="current-password"
                />
                {isErrorVisible && (
                  <Alert
                    onClose={() => setErrorVisible(false)}
                    severity="error"
                  >
                    Неправильный Логин или пароль!
                  </Alert>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    disabled={formik.isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#004479" }}
                  >
                    Войти
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
