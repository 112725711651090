import { Link as RouterLink } from "react-router-dom";
import Logo from "./Logo";
import React from "react";
import { AppBar, Toolbar } from "@mui/material";

const MainNavbar = (props: any) => (
  <AppBar elevation={0} {...props} className='loginNavbar'>
    <Toolbar sx={{ height: 80 }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
