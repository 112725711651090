import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from "@microsoft/signalr";
import { Dispatch } from "react";
import authService, { ClaimCode } from "../../application/service/AuthService";
import {
  loadAgendaPageEventAsync,
  loadAgendaUpcomingEventDates,
  setVoteModalVisible,
} from "../../application/slices/agendaSlice";
import {
  changeIsVoting,
  loadShowEventPageEventAsync,
  loadUpcomingEventDates,
  setQuorumCounter,
  updateVotedQuestions,
} from "../../application/slices/showEventSlice";

class EventManagementHub {
  connection!: HubConnection;
  userMessage = {};
  navigation: (path: string) => void;
  user: any = authService.getUser();
  dispatch: Dispatch<any>;

  constructor(
    token: string,
    navigation: (path: string) => void,
    dispatch: Dispatch<any>
  ) {
    this.navigation = navigation;
    this.dispatch = dispatch;
    this.connection = this.createConnection(token);
    this.connectionStart();
  }

  getInstance = () => {
    return this;
  };

  getConnection = () => {
    return this.connection;
  };

  createConnection = (token: string) => {
    const hubEndpoint = window.location.origin + "/hubs/EventManagement";
    const navigation = this.navigation;
    const user = this.user;
    const dispatch = this.dispatch;

    let hubConnection = new HubConnectionBuilder()
      .withUrl(hubEndpoint, {
        accessTokenFactory: () => token,
      } as IHttpConnectionOptions)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    hubConnection.on("UpdateVotedQuestions", function (questions) {
      if (
        authService.isCurrentUserAnAdmin() ||
        authService.isCurrentUserAPanel()
      ) {
        dispatch(updateVotedQuestions(questions));
      }
    });

    hubConnection.on("SendCommand", function (command) {
      console.log(command);
      switch (command) {
        case "StartEvent":
          if (authService.isCurrentUserADelegate()) {
            dispatch(loadAgendaPageEventAsync());
          }

          if (authService.isCurrentUserAPanel()) {
            dispatch(loadShowEventPageEventAsync());
          }

          if (authService.isCurrentUserAnAdmin()) {
            navigation("/events/live");
          }

          break;
        case "StopEvent":
          if (authService.isCurrentUserADelegate()) {
            dispatch(loadAgendaPageEventAsync());
          }

          if (authService.isCurrentUserAPanel()) {
            dispatch(loadShowEventPageEventAsync());
          }
          if (authService.isCurrentUserAnAdmin()) {
            dispatch(loadShowEventPageEventAsync());
            navigation("/events");
          }

          break;
        case "StartVoting":
          if (authService.isCurrentUserADelegate()) {
            dispatch(setVoteModalVisible(true));
          }
          if (authService.isCurrentUserAPanel()) {
            dispatch(changeIsVoting(true));
          }
          break;
        case "CompleteVoting":
          if (authService.isCurrentUserADelegate()) {
            dispatch(setVoteModalVisible(false));
          }
          if (authService.isCurrentUserAPanel()) {
            dispatch(changeIsVoting(false));
          }
          break;
        case "ReloadEvent":
        case "UpdateEventQuestions":
          if (
            authService.isCurrentUserAPanel() ||
            authService.isCurrentUserAnAdmin()
          ) {
            dispatch(loadShowEventPageEventAsync());
          }
          if (authService.isCurrentUserADelegate()) {
            dispatch(loadAgendaPageEventAsync());
          }
          break;
        case "UpdateQuestionPositions":
          if (authService.isCurrentUserAPanel()) {
            dispatch(loadShowEventPageEventAsync());
          }
          if (authService.isCurrentUserADelegate()) {
            dispatch(loadAgendaPageEventAsync());
          }
          break;
        case "PublishEvent":
        case "CancelPublishEvent":
          if (authService.isCurrentUserADelegate()) {
            dispatch(loadAgendaUpcomingEventDates());
          }

          if (authService.isCurrentUserAPanel()) {
            dispatch(loadUpcomingEventDates());
          }

          break;
      }
    });

    hubConnection.on("UpdateQuorum", function (delegates) {
      console.log("Quorum counter: ", delegates);
      dispatch(setQuorumCounter(delegates));
    });

    hubConnection.on("ReceiveTestMessage", function (sender, message) {
      console.log(sender, message);
    });

    hubConnection.on("ChangeDelegatePage", function (path) {
      console.log("ChangeDelegatePage", path);
      if (user.Permission.some((x: string) => x === ClaimCode.Agenda)) {
        navigation(path);
      }
    });

    hubConnection.on("ChangePanelPage", function (path) {
      console.log("ChangeDelegatePage", path);
      if (
        user.Permission.some((x: string) => x === ClaimCode.VotingDetails) &&
        user.Permission.some((x: string) => x === ClaimCode.VotingActions)
      ) {
        navigation(path);
      }
    });

    hubConnection.onclose(() => {
      const token = sessionStorage.getItem("user");
      if (token != null) {
        this.connectionStart();
      }
    });

    return hubConnection;
  };

  connectionStart = () => {
    try {
      this.connection.start();
      console.log("Connection started!");
    } catch (err) {
      console.log("Connection error!");
      console.log(err);
      setTimeout(() => this.connectionStart(), 5000);
    }
  };

  sendTestMessage = () => {
    this.connection.invoke("SendTestMessage", "Hello World").catch((err) => {
      console.log(err);
    });
  };
}

export default EventManagementHub;
