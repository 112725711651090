import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import {
  AccountVm,
  DeleteParticipantCommand,
  EventParticipantType,
  EventParticipantVm,
  ParticipantsClient,
  ParticipantType,
} from "../../../../application/service/ApiClient";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import { useLangs } from "../../../../application/hooks/useLangs";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import avatar from "../../../../assets/images/icons/user.svg";
import { useSnackbar } from "notistack";
import { participantsService } from "../../../../application/service";

interface Props {
  isReadonly: boolean;
  eventId?: number;
  delegateAccounts: AccountVm[];
  invitedAccounts: AccountVm[];
}

const ParticipantsFormContainer: React.FC<Props> = ({
  eventId,
  delegateAccounts,
  isReadonly,
  invitedAccounts,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const DELEGATES = useLangs("DELEGATES");
  const INVITED = useLangs("INVITED");

  let readonlyColumns: GridColDef[] = [
    {
      field: "fullName",
      headerName: useLangs("FULL_NAME"),
      width: 400,
      renderCell: (params: GridRenderCellParams) => {
        let account = delegateAccounts.find((x) => x.id === params.row.userId);

        if (account === null || account === undefined) {
          account = invitedAccounts.find((x) => x.id === params.row.userId);
        }

        return (
          <div className="usersAvatar">
            <img
              src={account?.photo || avatar}
              alt="avatar"
              className="avatar"
            />
            <p style={{ color: "#004479" }}>{account?.fullName}</p>
          </div>
        );
      },
    },
  ];

  let columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: useLangs("FULL_NAME"),
      width: 400,
      renderCell: (params: GridRenderCellParams) => {
        const photo = params.row.photo;

        return (
          <div className="usersAvatar">
            <img
              src={photo === undefined || photo === null ? avatar : photo}
              alt="avatar"
              className="avatar"
            />
            <p style={{ color: "#004479" }}>{params?.row?.fullName}</p>
          </div>
        );
      },
    },
  ];

  let invitedColumns = [
    ...columns,
    {
      field: "id",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (isReadonly) {
          return <></>;
        }

        return (
          <div>
            {participants
              ?.filter((x) => x.participantType === ParticipantType.Invited)
              .some((x) => x.userId === params.row.id) ? (
              <IconButton
                onClick={() => deleteParticipant(params.row.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                onClick={() =>
                  addParticipant(params.row.id, EventParticipantType.Invited)
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const delegatesColumns = [
    ...columns,
    {
      field: "id",
      headerName: " ",
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (isReadonly) {
          return <></>;
        }

        const isSelected = participants
          ?.filter((x) => x.participantType === ParticipantType.Delegate)
          .some((x) => x.userId === params.row.id);

        const castingVotes =
          participants?.filter(
            (x) => x.userId === params.id && x.hasCastingVote
          ) || [];

        const hasCastingVote = castingVotes?.length > 0;

        return (
          <div>
            {isSelected ? (
              <>
                <IconButton
                  onClick={() => deleteParticipant(params.row.id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => setCastingVote(params.row.id)}>
                  <ThumbUpIcon />
                </IconButton>{" "}
                {hasCastingVote ? (
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    {DECISION_MAKER}
                  </span>
                ) : (
                  ""
                )}
              </>
            ) : (
              <IconButton
                size="large"
                onClick={() =>
                  addParticipant(params.row.id, EventParticipantType.Delegate)
                }
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const classes = useStyles();
  const ERROR = useLangs("ERROR");
  const SUCCESSFULLY_ADDED = useLangs("SUCCESSFULLY_ADDED");
  const DECISION_MAKER = useLangs("DECISION_MAKER");
  const SUCCESSFULLY_DELETED = useLangs("SUCCESSFULLY_DELETED");
  const [participants, setParticipants] = useState<EventParticipantVm[]>([]);
  const [loading, setLoading] = useState(false);

  const loadEventParticipants = async () => {
    setLoading(true);
    try {
      const participants = await participantsService.getParticipants(eventId!);
      setParticipants(participants);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (eventId !== undefined) {
      loadEventParticipants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const deleteParticipant = async (participantId: string) => {
    try {
      const participantsClient = new ParticipantsClient();
      const deleteParticipantCommand: DeleteParticipantCommand = {
        eventId: eventId,
        participantId,
      };
      await participantsClient.delete(deleteParticipantCommand);
      const updatedParticipants = participants.filter(
        (x) => x.userId !== participantId
      );
      setParticipants(updatedParticipants);

      enqueueSnackbar(SUCCESSFULLY_DELETED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const addParticipant = async (
    participantId: string,
    type: EventParticipantType
  ) => {
    try {
      const participantsClient = new ParticipantsClient();
      await participantsClient.create(eventId, participantId, type);
      loadEventParticipants();
      enqueueSnackbar(SUCCESSFULLY_ADDED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const setCastingVote = async (participantId: string) => {
    try {
      const participantsClient = new ParticipantsClient();
      await participantsClient.setCastingVote({
        eventId: eventId,
        participantId,
      });
      loadEventParticipants();
      enqueueSnackbar(SUCCESSFULLY_ADDED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  return (
    <div>
      <Grid xs={12} sm={12}>
        <h2 style={{ marginBottom: "10px" }}>{DELEGATES}</h2>
        <React.Fragment>
          <div className={classes.table}>
            <DataGrid
              rowHeight={60}
              rows={
                isReadonly
                  ? participants.filter(
                      (f) => f.participantType === ParticipantType.Delegate
                    ) || []
                  : delegateAccounts
              }
              columns={isReadonly ? readonlyColumns : delegatesColumns}
              loading={loading}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              disableColumnMenu
            />
          </div>
        </React.Fragment>
      </Grid>
      <Grid xs={12} sm={12}>
        <h2 style={{ marginBottom: "10px" }}>{INVITED}</h2>
        <React.Fragment>
          <div className={classes.table}>
            <DataGrid
              rowHeight={60}
              rows={
                isReadonly
                  ? participants.filter(
                      (f) => f.participantType === ParticipantType.Invited
                    )
                  : invitedAccounts
              }
              columns={isReadonly ? readonlyColumns : invitedColumns}
              loading={loading}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              disableColumnMenu
            />
          </div>
        </React.Fragment>
      </Grid>
    </div>
  );
};
const useStyles = makeStyles({
  table: {
    height: 400,
    width: "100%",
    marginBottom: 20,
  },
});

export default ParticipantsFormContainer;
