import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import React from "react";
import Roles from "./roles/RolesContainer";
import Users from "./users/UsersContainer";
import InvitedUsers from "./users/InvitedUserContainer";
import { useLangs } from "../../../application/hooks/useLangs";

type TabPanelProps = {
  children: React.ReactNode;
  value: number;
  index: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}

const Settings = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="settings tabs"
              className='mainTab'
            >
              <Tab label={useLangs("DELEGATES")} {...a11yProps(0)} />
              <Tab label={useLangs("INVITED")} {...a11yProps(1)} />
              <Tab label={useLangs("ROLES")} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Users />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InvitedUsers />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Roles />
          </TabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default Settings;
