import * as React from "react";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  EventQuestionDto,
  VoteChoice,
} from "../../../application/service/ApiClient";
import { useLangs } from "../../../application/hooks/useLangs";
import { Box, Grid, Link, Paper } from "@mui/material";
import avatar from "../../../assets/images/icons/user.svg";
import { Component } from "react";
class MultiColorProgressBar extends Component<any | any> {
  render() {
    const parent = this.props;

    let values =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: {}; color: any },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="value"
              style={{
                color: item.color,
                width: item.value + "%",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              key={i}
            >
              <span>{item.value}%</span>
            </div>
          );
        }
        return <></>;
      },
      this);

    let calibrations =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: string | number; color: any },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="graduation"
              style={{
                color: item.color,
                width: item.value + "%",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              key={i}
            >
              <span>|</span>
            </div>
          );
        }
        return <></>;
      },
      this);

    let bars =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: string | number; color: any; countVote: number },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="bar"
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: item.color,
                width: item.value + "%",
                paddingTop: "10px",
                fontSize: "18px",
              }}
              key={i}
            >
              {item.countVote}
            </div>
          );
        }
        return <></>;
      },
      this);

    return (
      <div className="multicolor-bar">
        <div className="values">{values === "" ? "" : values}</div>
        <div className="scale">{calibrations === "" ? "" : calibrations}</div>
        <div className="bars">{bars === "" ? "" : bars}</div>
      </div>
    );
  }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "30px",
  },

  box: {
    marginTop: "70px",
  },

  red: {
    color: "#9c1506",
    fontSize: "x-large",
  },

  green: {
    color: "#25b068",
    fontSize: "x-large",
  },

  darkGrey: {
    color: "rgb(145 145 145)",
    fontSize: "x-large",
  },

  font: {
    fontStyle: "bold",
    color: "black",
    boxShadow: "none",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

type Props = {
  isOpen: boolean;
  eventQuestion?: EventQuestionDto;
  onClose: () => void;
};

const VotingDetailsDialog: React.FC<Props> = ({
  isOpen,
  eventQuestion,
  onClose,
}) => {
  const classes = useStyles();

  const LANG = {
    DECISION_NOT_MADE: useLangs("DECISION_NOT_MADE"),
    PER: useLangs("PER"),
    AGAINST: useLangs("AGAINST"),
    ABSENT: useLangs("ABSENT"),
    ACCEPTED: useLangs("ACCEPTED"),
    VOTING_RESULTS: useLangs("VOTING_RESULTS"),
  };

  const acceptedVotes =
    eventQuestion &&
    eventQuestion.votes?.filter((x) => x.choice === VoteChoice.Accept);
  const declinedVotes =
    eventQuestion &&
    eventQuestion.votes?.filter((x) => x.choice === VoteChoice.Decline);

  const absentVotes =
    eventQuestion &&
    eventQuestion.votes?.filter((x) => x.choice === VoteChoice.Absent);

  // const countNotVoted = Math.round(
  //   100 -
  //     (eventQuestion?.acceptedPercentage ?? 0) -
  //     (eventQuestion?.declinedPercentage ?? 0)
  // );

  let readings = [
    {
      value: eventQuestion?.acceptedPercentage,
      color: "#25b068",
      countVote: acceptedVotes?.length,
    },
    {
      value: eventQuestion?.declinedPercentage,
      color: "#9c1506",
      countVote: declinedVotes?.length,
    },
    {
      value: eventQuestion?.absentPercentage,
      color: "rgb(145 145 145)",
      countVote: absentVotes?.length,
    },
  ];

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={true}
        maxWidth="xl"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          {LANG.VOTING_RESULTS}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            key={eventQuestion && eventQuestion.id}
            className="segment"
            style={{ marginTop: "10px", height: "77vh", overflowY: "scroll" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Link underline="none" style={{ fontSize: "18px" }}>
                  {eventQuestion && eventQuestion.title}
                </Link>
              </Box>
              {eventQuestion?.isDecisionMade ? (
                <Box className="detailedHeaderStatusGreen">{LANG.ACCEPTED}</Box>
              ) : (
                <Box className="detailedHeaderStatusRed">
                  {LANG.DECISION_NOT_MADE}
                </Box>
              )}
            </Box>
            <MultiColorProgressBar readings={readings} />
            <Grid className={classes.center} container spacing={1}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid className={classes.box} container spacing={{ xs: 2 }}>
                  <Grid item xs={4}>
                    <div
                      style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}
                    >
                      <span className={classes.green}>●</span>
                      {LANG.PER}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}
                    >
                      <span className={classes.red}>●</span>
                      {LANG.AGAINST}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}
                    >
                      <span className={classes.darkGrey}>●</span>
                      {LANG.ABSENT}
                    </div>
                  </Grid>
                  <Grid borderRight={"4px solid rgb(63, 106, 184)"} item xs={4}>
                    {acceptedVotes?.map((v) => (
                      <Item key={v.id} className={classes.font}>
                        <div className="usersAvatar">
                          <img
                            src={
                              v.photo === undefined || v.photo === null
                                ? avatar
                                : v.photo
                            }
                            alt="avatar"
                            className="avatar"
                          />
                          <p style={{ color: "#004479" }}>{v.fullName}</p>
                        </div>
                      </Item>
                    ))}
                  </Grid>
                  <Grid borderRight={"4px solid rgb(63, 106, 184)"} item xs={4}>
                    {declinedVotes?.map((v) => (
                      <Item key={v.id} className={classes.font}>
                        <div className="usersAvatar">
                          <img
                            src={
                              v.photo === undefined || v.photo === null
                                ? avatar
                                : v.photo
                            }
                            alt="avatar"
                            className="avatar"
                          />
                          <p style={{ color: "#004479" }}>{v.fullName}</p>
                        </div>
                      </Item>
                    ))}
                  </Grid>
                  <Grid item xs={4}>
                    {absentVotes?.map((v) => (
                      <Item key={v.id} className={classes.font}>
                        <div className="usersAvatar">
                          <img
                            src={
                              v.photo === undefined || v.photo === null
                                ? avatar
                                : v.photo
                            }
                            alt="avatar"
                            className="avatar"
                          />
                          <p style={{ color: "#004479" }}>{v.fullName}</p>
                        </div>
                      </Item>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            {useLangs("CLOSE")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default VotingDetailsDialog;
