import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Grid, Link, Paper } from "@mui/material";
import React from "react";
import {
  EventParticipantDto,
  EventQuestionDto,
  ParticipantType,
  VoteChoice,
} from "../../../application/service/ApiClient";
import { useLangs } from "../../../application/hooks/useLangs";
import avatar from "../../../assets/images/icons/user.svg";
import { Component } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/redux/store";

class MultiColorProgressBar extends Component<any | any> {
  render() {
    const parent = this.props;

    let values =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: {}; color: any },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="value"
              style={{
                color: item.color,
                width: item.value + "%",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              key={i}
            >
              <span>{item.value}%</span>
            </div>
          );
        }
        return <></>;
      },
      this);

    let calibrations =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: string | number; color: any },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="graduation"
              style={{
                color: item.color,
                width: item.value + "%",
                fontSize: "18px",
                fontWeight: "bold",
              }}
              key={i}
            >
              <span>|</span>
            </div>
          );
        }
        return <></>;
      },
      this);

    let bars =
      parent.readings &&
      parent.readings.length &&
      parent.readings.map(function (
        item: { value: string | number; color: any; countVote: number },
        i: React.Key | null | undefined
      ) {
        if (item.value > 0) {
          return (
            <div
              className="bar"
              style={{
                textAlign: "center",
                color: "white",
                backgroundColor: item.color,
                width: item.value + "%",
                paddingTop: "10px",
                fontSize: "18px",
              }}
              key={i}
            >
              {item.countVote}
            </div>
          );
        }
        return <></>;
      },
      this);

    return (
      <div className="multicolor-bar">
        <div className="values">{values === "" ? "" : values}</div>
        <div className="scale">{calibrations === "" ? "" : calibrations}</div>
        <div className="bars">{bars === "" ? "" : bars}</div>
      </div>
    );
  }
}

type Props = {
  questions?: EventQuestionDto[];
  totalParticipants: number;
  absentParticipants: EventParticipantDto[];
};

const useStyles = makeStyles({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "30px",
  },

  box: {
    marginTop: "70px",
  },
  red: {
    color: "#9c1506",
    fontSize: "x-large",
  },
  green: {
    color: "#25b068",
    fontSize: "x-large",
  },
  darkGrey: {
    color: "rgb(145 145 145)",
    fontSize: "x-large",
  },

  font: {
    fontStyle: "bold",
    color: "black",
    boxShadow: "none",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const VotingDetailsItem: React.FC<Props> = ({
  questions,
  totalParticipants,
  absentParticipants,
}) => {
  const classes = useStyles();
  const { event } = useSelector((state: RootState) => state.showEvent);
  const LANG = {
    DECISION_NOT_MADE: useLangs("DECISION_NOT_MADE"),
    PER: useLangs("PER"),
    AGAINST: useLangs("AGAINST"),
    ABSENT: useLangs("ABSENT"),
    DID_NOT_VOTE: useLangs("DID_NOT_VOTE"),
  };

  if (questions === undefined) {
    return <CircularProgress />;
  }

  const acceptedVotes = questions[0].votes?.filter(
    (x) => x.choice === VoteChoice.Accept
  );
  const declinedVotes = questions[0].votes?.filter(
    (x) => x.choice === VoteChoice.Decline
  );
  // const absentVotes = questions[0].votes?.filter(
  //   (x) => x.choice === VoteChoice.Absent
  // );

  const acceptedPercentage = Math.round(
    ((acceptedVotes?.length ?? 0 * 100) / totalParticipants) * 100
  );
  const declinedPercentage = Math.round(
    ((declinedVotes?.length ?? 0 * 100) / totalParticipants) * 100
  );

  // const absentPercentage = Math.round(
  //   (absentParticipants.length / totalParticipants) * 100
  // );

  const countNotVoted = Math.round(
    100 - acceptedPercentage - declinedPercentage
  );

  const notVoted = Math.round(
    totalParticipants -
      (acceptedVotes?.length ?? 0) -
      (declinedVotes?.length ?? 0)
  );

  let readings = [
    {
      value: acceptedPercentage,
      color: "#25b068",
      countVote: acceptedVotes?.length,
    },
    {
      value: declinedPercentage,
      color: "#9c1506",
      countVote: declinedVotes?.length,
    },
    {
      value: countNotVoted,
      color: "rgb(145 145 145)",
      countVote: notVoted,
    },
  ];
  return (
    <div
      className="segment"
      style={{ marginTop: "10px", height: "77vh", overflow: "auto" }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {questions.map((q: EventQuestionDto) => (
            <div>
              <Link
                underline="none"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#3F6AB8",
                }}
              >
                {`${q.index}. ${q.title}`}
              </Link>
            </div>
          ))}
        </Box>
      </Box>
      <MultiColorProgressBar readings={readings} />
      <Grid className={classes.center} container spacing={1}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid className={classes.box} container spacing={{ xs: 2 }}>
            <Grid item xs={4}>
              <div style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}>
                <span className={classes.green}>●</span>
                {LANG.PER}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}>
                <span className={classes.red}>●</span>
                {LANG.AGAINST}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ fontWeight: "bold", color: "rgb(63, 106, 184)" }}>
                <span className={classes.darkGrey}>●</span>
                {LANG.DID_NOT_VOTE}
              </div>
            </Grid>
            <Grid borderRight={"4px solid rgb(63, 106, 184)"} item xs={4}>
              {acceptedVotes?.map((v) => (
                <Item key={v.id} className={classes.font}>
                  <div className="usersAvatar">
                    <img
                      src={
                        v.photo === undefined || v.photo === null
                          ? avatar
                          : v.photo
                      }
                      alt="avatar"
                      className="avatar"
                    />
                    <p style={{ color: "#004479" }}>{v.fullName}</p>
                  </div>
                </Item>
              ))}
            </Grid>
            <Grid borderRight={"4px solid rgb(63, 106, 184)"} item xs={4}>
              {declinedVotes?.map((v) => (
                <Item key={v.id} className={classes.font}>
                  <div className="usersAvatar">
                    <img
                      src={
                        v.photo === undefined || v.photo === null
                          ? avatar
                          : v.photo
                      }
                      alt="avatar"
                      className="avatar"
                    />
                    <p style={{ color: "#004479" }}>{v.fullName}</p>
                  </div>
                </Item>
              ))}
            </Grid>
            <Grid item xs={4}>
              {event?.participants
                ?.filter((x) => x.participantType === ParticipantType.Delegate)
                .filter(
                  (x) => !acceptedVotes?.some((p) => p.userId === x.userId)
                )
                .filter(
                  (x) => !declinedVotes?.some((p) => p.userId === x.userId)
                )
                .map((v) => (
                  <Item key={v.id} className={classes.font}>
                    <div className="usersAvatar">
                      <img
                        src={
                          v.photo === undefined || v.photo === null
                            ? avatar
                            : v.photo
                        }
                        alt="avatar"
                        className="avatar"
                      />
                      <p style={{ color: "#004479" }}>{v.fullName}</p>
                    </div>
                  </Item>
                ))}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default VotingDetailsItem;
