import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSpring } from "react-spring";
import ShowQuestionInfo from "./ShowQuestionInfo";
import authService, {
  ClaimCode,
} from "../../../application/service/AuthService";
import { useLangs } from "../../../application/hooks/useLangs";
import { EventQuestionDto } from "../../../application/service/ApiClient";

type Props = {
  questions?: EventQuestionDto[];
  isVoteModalVisible: boolean;
};

const AgendaQuestionsComponent: React.FC<Props> = ({
  questions,
  isVoteModalVisible,
}) => {
  const [isVisiblePlanInfoCard, setIsVisiblePlanInfoCard] = useState(false);
  const [questionInfo, setQuestionInfo] = useState<EventQuestionDto>({
    id: 0,
    title: "",
    proposedBy: "",
    type: "",
    status: undefined,
    acceptedCount: 0,
    declinedCount: 0,
  });
  const user: any = authService.getUser();

  const handleVisiblePlanInfoCard = () =>
    setIsVisiblePlanInfoCard(!isVisiblePlanInfoCard);

  useEffect(() => {
    if (questionInfo?.id! > 0) {
      setIsVisiblePlanInfoCard(true);
    } else {
      setIsVisiblePlanInfoCard(false);
    }
  }, [questionInfo]);

  useEffect(() => {
    if (isVoteModalVisible) {
      setIsVisiblePlanInfoCard(false);
    }
  }, [isVoteModalVisible]);

  useEffect(() => {
    if (!isVisiblePlanInfoCard) {
      setQuestionInfo({
        id: 0,
        title: "",
        proposedBy: "",
        type: "",
        status: undefined,
        acceptedCount: 0,
        declinedCount: 0,
      });
    }
  }, [isVisiblePlanInfoCard]);

  const contentProps = useSpring({
    // marginRight: isVisiblePlanInfoCard ? 0 : -600,
    width: isVisiblePlanInfoCard ? 600 : 0,
    display: isVisiblePlanInfoCard ? "block" : "none",
  });

  return (
    <React.Fragment>
      <div className="segment" style={{ overflow: "hidden" }}>
        <Table
          sx={{ minWidth: 500, height: "100%" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>{useLangs("QUESTION")}</TableCell>
              <TableCell>{useLangs("SPEAKER")}</TableCell>
              {user.Permission.some(
                (x: string) => x === ClaimCode.VotingActions
              ) && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {questions?.map((item) => (
              <TableRow onClick={() => setQuestionInfo(item)}>
                <TableCell component="th" scope="row">
                  {item.index}
                </TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.proposedBy}</TableCell>
                {user.Permission.some(
                  (x: string) => x === ClaimCode.VotingActions
                ) && (
                  <TableCell>
                    <IconButton size="medium">
                      <EditIcon />
                    </IconButton>
                    <IconButton size="medium">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ShowQuestionInfo
          style={contentProps}
          questionInfo={questionInfo}
          handleVisiblePlanInfoCard={handleVisiblePlanInfoCard}
        />
      </div>
    </React.Fragment>
  );
};

export default AgendaQuestionsComponent;
