import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import React from "react";
import { useFormik } from "formik";
import { EventDetailVm } from "../../../application/service/ApiClient";
import { useSnackbar } from "notistack";
import MainFormComponent from "../../component/events/MainFormComponent";
import { useNavigate } from "react-router-dom";
import { useLangs } from "../../../application/hooks/useLangs";
import { LoadingButton } from "@mui/lab";
import { eventsService } from "../../../application/service";
import { removeTimeZone } from "../../../application/helpers/dateHelper";
import * as yup from "yup";

const CreateEvent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const LANG = {
    BACK: useLangs("BACK"),
    CREATE_EVENT: useLangs("CREATE_EVENT"),
    SAVE: useLangs("SAVE"),
    ERROR: useLangs("ERROR"),
    SUCCESSFULLY_EVENT_CREATED: useLangs("SUCCESSFULLY_EVENT_CREATED"),
  };

  const validationSchema = yup.object({
    title: yup.string().required("Обязательное поле"),
    startDate: yup.date().required("Обязательное поле"),
    type: yup.string().required("Обязательное поле"),
  });

  const formik = useFormik<EventDetailVm>({
    initialValues: {
      title: "",
      startDate: undefined,
      type: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => createEventInfo(values),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const createEventInfo = async (values: EventDetailVm) => {
    try {
      const eventId = await eventsService.createEventInfo(
        values.title,
        removeTimeZone(values.startDate),
        values.type,
        values.description
      );
      enqueueSnackbar(LANG.SUCCESSFULLY_EVENT_CREATED, { variant: "success" });
      navigate(`/events/card/${eventId}`);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(LANG.ERROR, { variant: "error" });
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <Button
            onClick={() => navigate(-1)}
            style={{ margin: "16px 16px 0" }}
            variant="outlined"
            startIcon={<KeyboardBackspace />}
            className="mainIconBtn"
          >
            {LANG.BACK}
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "10px",
            }}
          >
            <CardHeader title={LANG.CREATE_EVENT} />
          </div>
          <Divider />
          <CardContent>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Box sx={{ width: "100%" }}>
                <MainFormComponent formik={formik} />

                <Grid
                  xs={12}
                  sm={12}
                  mb={2}
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ marginTop: 5 }}
                >
                  <LoadingButton
                    loading={formik.isSubmitting}
                    onClick={formik.submitForm}
                  >
                    {LANG.SAVE}
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </form>
    </React.Fragment>
  );
};

export default CreateEvent;
