import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import React from "react";
import EventManagementHub from "../../hub/EventManagementHub";
import authService, {
  ClaimCode,
} from "../../../application/service/AuthService";
import { useDispatch } from "react-redux";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 100,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 265,
  },
  paddingRight: 15,
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  padding: "15px",
});

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const [eventManagementHub, setEventManagementHubInstance] =
    useState<EventManagementHub | null>(null);
  const navigate = useNavigate();
  const user: any = authService.getUser();
  const token = authService.getAccessToken();
  if (token == null) {
    window.location.replace("/account/login");
  }

  if (!((eventManagementHub as any) instanceof Object)) {
    let eventManagementHub = new EventManagementHub(token!, navigate, dispatch);
    if (eventManagementHub.connection instanceof Object) {
      setEventManagementHubInstance(eventManagementHub);
    }
  }
  const mainRootWrapClass =
    user?.Permission.some((x: string) => x !== ClaimCode.Settings) &&
    user?.role === "Панель"
      ? "DashboardLayoutRootClass"
      : "";
  return (
    <DashboardLayoutRoot className={mainRootWrapClass}>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {user?.Permission.some((x: string) => x === ClaimCode.Settings) && (
        <DashboardSidebar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      )}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
