import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import { useLangs } from "../../../application/hooks/useLangs";
import {
  EventQuestionUploadedFileVm,
  QuestionVm,
} from "../../../application/service/ApiClient";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  editingQuestion?: QuestionVm;
  onUploadFromFile: (files: File[]) => void;
  onCancel: () => void;
  onSubmit: (values: QuestionVm) => void;
};

const QuestionFormComponent: React.FC<Props> = ({
  editingQuestion,
  onUploadFromFile,
  onCancel,
  onSubmit,
}) => {
  const ADD_QUESTION = useLangs("ADD_QUESTION");
  const UPDATE_QUESTION = useLangs("UPDATE_QUESTION");
  const UPLOAD_FROM_FILE = useLangs("UPLOAD_FROM_FILE");
  const NAME = useLangs("NAME");
  const CANCEL = useLangs("CANCEL");
  const ATTACH_FILE = useLangs("ATTACH_FILE");
  const TYPE = useLangs("TYPE");
  const PROPOSED_BY = useLangs("PROPOSED_BY");

  const formik = useFormik<QuestionVm>({
    initialValues: {
      title: "",
      type: "",
      proposedBy: "",
    },
    onSubmit: async (values) => {
      await onSubmit(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (editingQuestion !== undefined) {
      formik.setValues(editingQuestion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingQuestion]);

  const handleDropQuestionFile = (files: EventQuestionUploadedFileVm[]) => {
    // setQuestionFiles([...questionFiles, ...files]);
    formik.setFieldValue(
      "attachments",
      [...(formik.values.attachments || []), ...files],
      false
    );
  };

  const deleteQuestionFile = (index: number) => {
    if (formik.values.attachments === undefined) {
      return;
    }
    let newQuestionFiles = formik.values.attachments.filter(
      (el, i) => i !== index
    );
    formik.setFieldValue("attachments", [...newQuestionFiles], false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleReset = () => {
    formik.resetForm();
    onCancel();
  };

  const isEditing = editingQuestion !== undefined;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid mb={2}>
        <TextField
          name="title"
          maxRows={4}
          multiline
          label={NAME}
          fullWidth
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid mb={2}>
        <TextField
          name="proposedBy"
          maxRows={1}
          label={PROPOSED_BY}
          fullWidth
          value={formik.values.proposedBy}
          onChange={formik.handleChange}
          error={formik.touched.proposedBy && Boolean(formik.errors.proposedBy)}
          helperText={formik.touched.proposedBy && formik.errors.proposedBy}
        />
      </Grid>
      <Grid mb={2}>
        <TextField
          name="type"
          maxRows={1}
          label={TYPE}
          fullWidth
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
        />
      </Grid>
      <Grid
        xs={12}
        sm={12}
        mb={2}
        item
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Dropzone onDrop={handleDropQuestionFile}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button component="label">
                  <AttachFileIcon />
                  {ATTACH_FILE}
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
        <ButtonGroup>
          {!isEditing && (
            <Dropzone onDrop={onUploadFromFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button variant="contained" style={{ marginRight: "10px" }}>
                      {UPLOAD_FROM_FILE}
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          {isEditing && (
            <Button color="error" onClick={handleReset}>
              {CANCEL}
            </Button>
          )}
          <Button onClick={handleSubmit}>
            {isEditing ? UPDATE_QUESTION : ADD_QUESTION}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={3} sm={3}>
        <React.Fragment>
          <List>
            {formik.values.attachments &&
              formik.values.attachments.map(
                (file: EventQuestionUploadedFileVm, index: number) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        onClick={() => deleteQuestionFile(index)}
                        edge="end"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={file.name} />
                  </ListItem>
                )
              )}
          </List>
        </React.Fragment>
      </Grid>
    </form>
  );
};

export default QuestionFormComponent;
