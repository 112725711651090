import { Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import {
  EventQuestionAttachmentsClient,
  EventQuestionUploadedFileVm,
  FileParameter,
  PaginatedListOfQuestionVm,
  QuestionVm,
} from "../../../../application/service/ApiClient";
import { useLangs } from "../../../../application/hooks/useLangs";
import { questionsService } from "../../../../application/service";
import QuestionForm from "../../../component/events/QuestionFormComponent";

interface Props {
  eventId?: number;
  isReadonly: boolean;
}

type UploadQuestionDataType = {
  title: string;
  proposedBy: string;
  type: string;
};

type XLSXQuestionDataType = {
  "Кем предложено"?: string;
  Наименование?: string;
  Тип?: string;
};

const QuestionsFormContainer: React.FC<Props> = ({ eventId, isReadonly }) => {
  const classes = useStyles();
  const pageSizeQuestionList = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<
    PaginatedListOfQuestionVm | undefined
  >(undefined);
  const [editingQuestion, setEditingQuestion] = useState<
    QuestionVm | undefined
  >(undefined);

  const getEventQuestions = async () => {
    setLoading(true);
    try {
      const response = await questionsService.getQuestions(
        eventId,
        currentPage + 1,
        10
      );
      setQuestions(response);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setLoading(false);
  };

  const deleteEventQuestion = async (id: number) => {
    setLoading(true);
    try {
      await questionsService.delete(id);
      getEventQuestions();
      enqueueSnackbar(SUCCESSFULLY_DELETED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setLoading(false);
  };

  const getFilesWithParameter = (
    files?: EventQuestionUploadedFileVm[]
  ): FileParameter[] | undefined => {
    if (files === undefined) {
      return undefined;
    }

    return files.map((el) => {
      let file: FileParameter = {
        data: el,
        fileName: el.name || "",
      };
      return file;
    });
  };

  const createEventQuestion = async (values: QuestionVm) => {
    try {
      await questionsService.create(
        eventId,
        values.title,
        values.proposedBy,
        values.type,
        getFilesWithParameter(values.attachments)
      );
      await getEventQuestions();

      enqueueSnackbar(SUCCESSFULLY_CREATED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(CANNOT_CREATE_QUESTION, { variant: "error" });
    }
  };

  const editEventQuestion = async (values: QuestionVm) => {
    console.log(values);
    try {
      const newAttachments =
        values.attachments &&
        values.attachments.filter((x) => x instanceof File);
      const deletedAttachmentIds =
        editingQuestion &&
        editingQuestion.attachments &&
        editingQuestion?.attachments
          .filter((x) => {
            const isExist =
              values.attachments &&
              values.attachments.find((p) => p.id === x.id);
            if (isExist === undefined || isExist === null) {
              return true;
            }
            return false;
          })
          .map((x) => x.id!);

      await questionsService.edit(
        values.id,
        values.title,
        values.proposedBy,
        values.type,
        deletedAttachmentIds,
        getFilesWithParameter(newAttachments)
      );
      await getEventQuestions();
      enqueueSnackbar(SUCCESSFULLY_UPDATED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(CANNOT_EDIT_QUESTION, { variant: "error" });
    }
    setEditingQuestion(undefined);
  };

  const handleCancelForm = () => {
    setEditingQuestion(undefined);
  };

  const handleSubmitForm = (values: QuestionVm) => {
    if (values.id === undefined) {
      createEventQuestion(values);
    } else {
      editEventQuestion(values);
    }
  };

  useEffect(() => {
    getEventQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const { enqueueSnackbar } = useSnackbar();

  const ERROR = useLangs("ERROR");
  const SUCCESSFULLY_DELETED = useLangs("SUCCESSFULLY_DELETED");
  const CANNOT_CREATE_QUESTION = useLangs("CANNOT_CREATE_QUESTION");
  const CANNOT_EDIT_QUESTION = useLangs("CANNOT_EDIT_QUESTION");
  const SUCCESSFULLY_CREATED = useLangs("SUCCESSFULLY_CREATED");
  const SUCCESSFULLY_UPDATED = useLangs("SUCCESSFULLY_UPDATED");

  const downloadFile = async (file: EventQuestionUploadedFileVm) => {
    try {
      const eventQuestionAttachmentsClient =
        new EventQuestionAttachmentsClient();
      let fileId: string = file.id || "";
      const fileResponse =
        await eventQuestionAttachmentsClient.downloadAttachment(fileId);
      saveAs(fileResponse.data, fileResponse.fileName);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const parseDataFromExcel = async (data: XLSXQuestionDataType[]) => {
    const uploadedList: UploadQuestionDataType[] = [];
    data.forEach((el: XLSXQuestionDataType) => {
      let uploadFileDataItem: UploadQuestionDataType = {
        title: el["Наименование"] ? el["Наименование"] : "",
        proposedBy: el["Кем предложено"] ? el["Кем предложено"] : "",
        type: el["Тип"] ? el["Тип"] : "",
      };
      uploadedList.push(uploadFileDataItem);
    });
    try {
      await questionsService.createBulk({
        eventId: eventId,
        questions: uploadedList,
      });
      await getEventQuestions();
    } catch (e) {
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const handleUploadFromFile = (files: File[]) => {
    let file = files[0];
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e?.target?.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json<XLSXQuestionDataType>(ws);
      parseDataFromExcel(data);
    };
    reader.onerror = (err) => {
      console.log(err);
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  let columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        let rowIndex =
          (questions &&
            questions.items!.findIndex((el) => el.id === params.id)) ||
          0;

        return currentPage * pageSizeQuestionList + rowIndex + 1;
      },
    },
    {
      field: "title",
      headerName: useLangs("QUESTION_NAME"),
      width: 700,
    },
    {
      field: "attachments",
      headerName: useLangs("FILES"),
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {params.row.attachments.map((el: EventQuestionUploadedFileVm) => (
              <div
                onClick={() => downloadFile(el)}
                style={{
                  color: "#7973dc",
                  fontSize: "16px",
                  textDecoration: "underline",
                  marginRight: "10px",
                  cursor: "pointer",
                  lineHeight: "24px",
                }}
              >
                {el.name}
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  if (!isReadonly) {
    columns = [
      ...columns,
      {
        field: "type",
        headerName: " ",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setEditingQuestion(params.row);
                }}
                size="large"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  const id: number = params.row.id || 0;
                  deleteEventQuestion(id);
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    ];
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {!isReadonly && (
            <>
              <QuestionForm
                onUploadFromFile={handleUploadFromFile}
                editingQuestion={editingQuestion}
                onCancel={handleCancelForm}
                onSubmit={handleSubmitForm}
              />
            </>
          )}

          <Grid item xs={12} sm={12}>
            <React.Fragment>
              <div className={classes.table}>
                <DataGrid
                  rowHeight={60}
                  rows={(questions && questions.items) || []}
                  columns={columns}
                  page={currentPage}
                  disableSelectionOnClick
                  disableColumnMenu
                  pagination
                  paginationMode="server"
                  loading={loading}
                  rowCount={questions?.totalCount}
                  rowsPerPageOptions={[10]}
                  pageSize={pageSizeQuestionList}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                />
              </div>
            </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  table: {
    height: 720,
    width: "100%",
  },
});

export default QuestionsFormContainer;
