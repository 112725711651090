import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Settings as SettingsIcon } from "react-feather";
import NavItem from "./NavItem";
import { Box, Drawer, Hidden, List } from "@mui/material";

import logo from "../../../assets/images/logo.png";
import { Dashboard, FormatListBulleted } from "@mui/icons-material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import authService, {
  ClaimCode,
} from "../../../application/service/AuthService";
import { useLangs } from "../../../application/hooks/useLangs";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/redux/store";

type DashboardSidebarProps = {
  onMobileClose: () => void;
  openMobile: boolean;
};

const DashboardSidebar = ({
  onMobileClose,
  openMobile,
}: DashboardSidebarProps) => {
  const location = useLocation();
  const { event } = useSelector((state: RootState) => state.showEvent);
  const user: any = authService.getUser();

  useEffect(() => {
    if (openMobile) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const AGENDA = useLangs("AGENDA");
  const EVENT = useLangs("EVENT");
  const SETTINGS = useLangs("SETTINGS");
  const IN_PROGRESS = useLangs("IN_PROGRESS");

  const canSeeLiveEvent = authService.isCurrentUserAnAdmin()
    ? event !== undefined && event !== null
    : authService.isCurrentUserAnAdmin();

  const content = (
    <Box className="sideMenuContent">
      <img src={logo} className="logo" alt="Логотип" title="Логотип" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box className="sideMenuDivider" />
        <Box sx={{ p: 2 }}>
          <List>
            {user &&
              user?.Permission.some((x: string) => x === ClaimCode.Agenda) && (
                <NavItem href="/agenda" title={AGENDA} icon={Dashboard} />
              )}
            {user &&
              user?.Permission.some((x: string) => x === ClaimCode.Events) && (
                <NavItem
                  href="/events"
                  title={EVENT}
                  icon={FormatListBulleted}
                />
              )}
            {user?.Permission.some((x: string) => x === ClaimCode.Settings) && (
              <NavItem href="/settings" title={SETTINGS} icon={SettingsIcon} />
            )}
            {canSeeLiveEvent && (
              <NavItem
                href="/events/live"
                title={IN_PROGRESS}
                icon={PlayArrowIcon}
              />
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="sideMenu">
      <Drawer
        anchor="right"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{ sx: { width: 250, backgroundColor: '#004479' } }}
      >
        {content}
      </Drawer>

      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 250,
              top: 0,
              height: "100vh",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default DashboardSidebar;
