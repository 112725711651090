import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { eventsService } from "../service";
import {
  ActiveEventVm,
  EventParticipantDto,
  EventQuestionDto,
  EventQuestionStatus,
} from "../service/ApiClient";

export const loadShowEventPageEventAsync = createAsyncThunk(
  "showEvent/getCurrentActiveEvent",
  async () => {
    const event = await eventsService.getCurrentActiveEvent();
    return event;
  }
);

export const loadUpcomingEventDates = createAsyncThunk(
  "showEvent/getUpcomingEventDates",
  async () => {
    const eventsDate: Date[] = await eventsService.getUpcomingEventDates();
    return eventsDate;
  }
);

export interface ShowEventState {
  event?: ActiveEventVm;
  loading: boolean;
  isVoting: boolean;
  quorumCounter: number;
  quorumParticipants: string[];
  absentParticipants: EventParticipantDto[];
  upcomingEventDates: Date[] | [];
}

const initialState: ShowEventState = {
  event: undefined,
  loading: false,
  isVoting: false,
  quorumCounter: 0,
  quorumParticipants: [],
  absentParticipants: [],
  upcomingEventDates: [],
};

export const showEventSlice = createSlice({
  name: "showEvent",
  initialState,
  reducers: {
    changeIsVoting: (state, action: PayloadAction<boolean>) => {
      state.isVoting = action.payload;
    },
    updateVotedQuestions: (
      state,
      action: PayloadAction<EventQuestionDto[]>
    ) => {
      const ev = state.event;
      if (ev !== undefined) {
        ev.eventQuestions = ev.eventQuestions?.map((x) => {
          var upQ = action.payload.find((o) => o.id === x.id);
          if (upQ !== undefined) {
            upQ.index = x.index;
            return upQ;
          }

          return x;
        });
      }
      state.event = Object.assign({}, state.event, ev);
    },
    setQuorumCounter: (state, action: PayloadAction<string[]>) => {
      state.quorumCounter = action.payload.length;
      state.quorumParticipants = action.payload;
      const absentUsers = state.event?.participants?.filter(
        (p) => !action.payload.some((x) => x === p.userId)
      );
      state.absentParticipants = absentUsers || [];
    },
    changeQuestionPositions: (
      state,
      action: PayloadAction<EventQuestionDto[]>
    ) => {
      const ev = state.event;
      if (ev !== undefined) {
        ev.eventQuestions = action.payload;
      }
      state.event = Object.assign({}, state.event, ev);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadShowEventPageEventAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadShowEventPageEventAsync.fulfilled,
        (state, action: PayloadAction<ActiveEventVm>) => {
          state.loading = false;
          const hasInProgressQuestions =
            action.payload &&
            action.payload.eventQuestions!.some(
              (x) => x.status === EventQuestionStatus.InProgress
            );
          state.isVoting = hasInProgressQuestions;
          state.event = Object.assign({}, state.event, action.payload);
        }
      )
      .addCase(loadShowEventPageEventAsync.rejected, (state) => {
        state.event = undefined;
        state.loading = false;
      });

    builder
      .addCase(loadUpcomingEventDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadUpcomingEventDates.fulfilled,
        (state, action: PayloadAction<Date[]>) => {
          state.upcomingEventDates = action.payload;
          state.loading = false;
        }
      )
      .addCase(loadUpcomingEventDates.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateVotedQuestions, changeIsVoting, setQuorumCounter, changeQuestionPositions } =
  showEventSlice.actions;

export default showEventSlice.reducer;
