import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  AccountClient,
  AccountType,
  AccountVm,
} from "../../../../application/service/ApiClient";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useLangs } from "../../../../application/hooks/useLangs";

const useStyles = makeStyles({
  table: {
    height: 400,
    width: "100%",
  },
});



const InvitedUsers = () => {
  const history = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<AccountVm[]>([]);

  const ACCOUNT_SUCCESSFULLY_DELETED = useLangs("ACCOUNT_SUCCESSFULLY_DELETED");
  const FIO = useLangs("FIO");
  const LAST_ENTRANCE = useLangs("LAST_ENTRANCE");
  const ROLES = useLangs("ROLES");
  const WANT_TO_DELETE = useLangs("WANT_TO_DELETE");
  const CONFIRM = useLangs("CONFIRM");
  const CANCEL = useLangs("CANCEL");
  const [open, setOpen] = useState(false);
  const [deleteAccountId, setDeleteAccountId] = useState<string | undefined>(
    undefined
  );
  const handleOpen = (id: string | undefined) => {
    setDeleteAccountId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setDeleteAccountId(undefined);
    setOpen(false);
  };
  

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: FIO,
      width: 350,
    },
    {
      field: "userName",
      headerName: "Логин",
      width: 300,
    },
    {
      field: "lastLoggedIn",
      headerName: LAST_ENTRANCE,
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        let value: Date
        if( typeof params.value == "string" ) {
          value = new Date(params.value) 
          return moment(value).format('DD-MM-YYYY HH:mm')
        }
      },
    },
    {
      field: "roles",
      headerName: ROLES,
      width: 200,
    },

    {
      field: "id",
      headerName: " ",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <IconButton
              onClick={() => history(`/users/edit/${params.row.id}?invited=1`)}
              size="large"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpen(String(params.row.id) || undefined)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const deleteAccount = async () => {
    try {
      setLoading(true);
      const accountsClient = new AccountClient();
      const command = { id: deleteAccountId};
      await accountsClient.delete(command);
      handleClose();
      enqueueSnackbar(ACCOUNT_SUCCESSFULLY_DELETED, { variant: "error" });
      loadAccounts();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  const loadAccounts = async () => {
    try {
      setLoading(true);
      const accountsClient = new AccountClient();
      const accounts = await accountsClient.getAccounts(AccountType.Invited);
      setAccounts(accounts);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="contained"
          onClick={() => history("/users/add?invited=1")}
          color="primary"
          className="defaultBtn mainBtn"
        >
          {useLangs("ADD")}
        </Button>
      </Grid>
      <br />
      <div className={classes.table}>
        <DataGrid
          rows={accounts}
          columns={columns}
          loading={loading}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {WANT_TO_DELETE}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#004479" }} onClick={deleteAccount}>
              {CONFIRM}
            </Button>
            <Button style={{ color: "#004479" }} onClick={handleClose}>{CANCEL}</Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InvitedUsers;
