import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import "./DecisionFormContainer.css";
import {
  EventDecisionVm,
  EventQuestionDto,
  EventQuestionStatus,
} from "../../../../application/service/ApiClient";
import VotingDetailsDialog from "../../../component/events/VotingDetailsDialogComponent";
import { useLangs } from "../../../../application/hooks/useLangs";
import { eventsService } from "../../../../application/service";
import { useSnackbar } from "notistack";

type Props = {
  eventId?: number;
};

const EventResult: React.FC<Props> = ({ eventId }) => {
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [decision, setDecision] = useState<EventDecisionVm | undefined>(
    undefined
  );
  const [currentQuestion, setCurrentQuestion] = useState<
    EventQuestionDto | undefined
  >(undefined);
  const ACCEPTED = useLangs("ACCEPTED");
  const DECLINED = useLangs("DECLINED");
  const ERROR = useLangs("ERROR");

  const getEventDecision = async () => {
    setLoading(true);
    try {
      const response = await eventsService.getEventDecision(eventId);
      setDecision(response);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventDecision();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDetails = (question: EventQuestionDto) => {
    setCurrentQuestion(question);
    setDetailsModalVisible(true);
  };

  const VOTING_IN_PROGRESS = useLangs("VOTING_IN_PROGRESS");
  const NOT_STARTED = useLangs("NOT_STARTED");

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <div className="eventsShowContent">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {decision &&
            decision.questions!.map((question) => {
              let status = null;
              switch (question.status) {
                case EventQuestionStatus.NotStarted:
                  status = (
                    <p className="eventsVotingNotStarted">{NOT_STARTED}</p>
                  );
                  break;
                case EventQuestionStatus.InProgress:
                  status = <p>{VOTING_IN_PROGRESS}</p>;
                  break;
                case EventQuestionStatus.Completed:
                  status = (
                    <p>
                      {question && question.isDecisionMade! ? (
                        <Box style={{ color: "green", fontWeight: "bold" }}>
                          {ACCEPTED}
                        </Box>
                      ) : (
                        <Box style={{ color: "red", fontWeight: "bold" }}>
                          {DECLINED}
                        </Box>
                      )}
                    </p>
                  );
                  break;
              }
              return (
                <Grid item xs={3} key={question.id}>
                  <Card
                    sx={{ minWidth: 275 }}
                    className="eventsShowCard"
                    onClick={() => handleViewDetails(question)}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          fontSize: 16,
                          color: "#3F6AB8",
                          fontWeight: "bold",
                        }}
                        className="eventsShowHeader"
                        title={question.title}
                      >
                        {question.title}
                      </Typography>
                      <Box className="eventStatics">
                        <Box className="eventsStaticsGreen">
                          {question.acceptedCount}
                        </Box>
                        <Box className="eventsStaticsRed">
                          {question.declinedCount}
                        </Box>
                      </Box>
                      <div className="eventsReview">
                        <div>
                          <p style={{ marginRight: "10px" }}>Статус: </p>
                          {status}
                        </div>
                        <div className="eventsReviewDivider"></div>
                        <div>
                          {/* <p style={{ marginRight: '10px' }}>{SPEAKER}:</p> */}
                          <p>{question.proposedBy ?? "-"}</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        <VotingDetailsDialog
          eventQuestion={currentQuestion}
          isOpen={isDetailsModalVisible}
          onClose={() => setDetailsModalVisible(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default EventResult;
