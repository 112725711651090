import React, { useContext, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { protocolTemplateService } from "../../../../application/service";
import { LanguageCode } from "../../../../application/service/ApiClient";
import { Button, CircularProgress } from "@mui/material";
import { Context } from "../../../../store/context";
import { useLangs } from "../../../../application/hooks/useLangs";

type Props = {
  eventId?: number;
};

const momentLocalLang = {
  KZ: "kk",
  RU: "ru",
};

const ProtocolFormContainer: React.FC<Props> = ({ eventId }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [newContent, setNewContent] = useState("");
  const {
    state: { lang },
  } = useContext(Context);
  const SAVE = useLangs("SAVE");

  useEffect(() => {
    const loadEventProtocolTemplate = async () => {
      if (momentLocalLang[lang] === "ru") {
        setLoading(true);
        try {
          const content =
            await protocolTemplateService.getEventProtocolTemplate(
              eventId,
              LanguageCode.Ru
            );
          setContent(content);
          setNewContent(content);
        } catch (e) {
          console.log(e);
        }
        setLoading(false);
      } else {
        setLoading(true);
        try {
          const content =
            await protocolTemplateService.getEventProtocolTemplate(
              eventId,
              LanguageCode.Kz
            );
          setContent(content);
          setNewContent(content);
        } catch (e) {
          console.log(e);
        }
        setLoading(false);
      }
    };

    loadEventProtocolTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleEditorChange = (e: any) => {
    setNewContent(e.target.getContent());
  };

  const handleSave = async () => {
    if (momentLocalLang[lang] === "ru") {
      setLoading(true);
      try {
        await protocolTemplateService.updateEventProtocolTemplate({
          eventId: eventId,
          content: newContent,
          language: LanguageCode.Ru,
        });
        setContent(newContent);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    } else {
      setLoading(true);
      try {
        await protocolTemplateService.updateEventProtocolTemplate({
          eventId: eventId,
          content: newContent,
          language: LanguageCode.Kz,
        });
        setContent(newContent);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Editor
        apiKey="hxu36w98biavmc4gqbnr92rztjkwe168majju3nt2x23wol7"
        initialValue={content}
        init={{
          height: 500,
          language: "ru",
          menubar: true,
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
          ],
          /*eslint no-multi-str: 0*/
          toolbar:
            "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
        }}
        onChange={handleEditorChange}
      />
      <Button onClick={handleSave}>{SAVE}</Button>
    </div>
  );
};

export default ProtocolFormContainer;
