import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  RoleDetailVm,
  RolesClient,
} from "../../../../application/service/ApiClient";
import { useSnackbar } from "notistack";
import { useLangs } from "../../../../application/hooks/useLangs";

const validationSchema = Yup.object({
  name: Yup.string().required("Обязательное поле"),
  description: Yup.string().required("Обязательное поле"),
});

const AddEditRole = () => {
  const { id } = useParams();
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const rolesClient = new RolesClient();

  const formik = useFormik<RoleDetailVm>({
    initialValues: {
      name: "",
      description: "",
      roleClaims: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id !== undefined) {
        handleEdit(values);
      } else {
        handleAdd(values);
      }
    },
  });

  useEffect(() => {
    if (id !== undefined) {
      loadRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRole = async () => {
    try {
      const role = await rolesClient.getRole(id as string);
      formik.setValues(role);
      formik.setFieldValue("roleClaims", role.roleClaims);
    } catch (e) {
      console.error(e);
    }
  };

  const handleEdit = async (values: RoleDetailVm) => {
    try {
      await rolesClient.editRole(values);
      enqueueSnackbar("Роль успешно обновлена", { variant: "success" });
      history("/settings");
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Ошибка", { variant: "error" });
    }
  };

  const handleAdd = async (values: RoleDetailVm) => {
    try {
      await rolesClient.addRole(values);
      enqueueSnackbar("Роль успешно создана", { variant: "success" });
      history("/settings");
    } catch (e) {
      console.error(e);
      enqueueSnackbar("Ошибка", { variant: "error" });
    }
  };

  const handleCheckboxChange = (name: string) => {
    const roleClaims = formik.values.roleClaims;
    if (roleClaims && roleClaims?.indexOf(name) !== -1) {
      const updatedRoleClaims = roleClaims?.filter((x) => x !== name);
      formik.setFieldValue("roleClaims", updatedRoleClaims);
    } else {
      roleClaims?.push(name);
      formik.setFieldValue("roleClaims", roleClaims);
    }
  };
  const EVENT = useLangs("EVENT");

  return (
    <React.Fragment>
      <Card>
        <CardHeader title={useLangs("EDIT_ROLE")} />
        <Divider />
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="name"
                  label={useLangs("TITLE")}
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="description"
                  maxRows={4}
                  multiline
                  label={useLangs("DESCRIPTION")}
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  {useLangs("VOTING")}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={formik.values.roleClaims?.includes(
                        "voting_actions"
                      )}
                      onChange={() => handleCheckboxChange("voting_actions")}
                      name="roleClaims"
                      value="voting_actions"
                    />
                  }
                  label={useLangs("VOTING_ADMINISTRATION")}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={formik.values.roleClaims?.includes(
                        "voting_details"
                      )}
                      onChange={() => handleCheckboxChange("voting_details")}
                      name="roleClaims"
                      value="voting_details"
                    />
                  }
                  label={useLangs("VOTING_TOTALS_DETAIL")}
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={formik.values.roleClaims?.includes("agenda")}
                      onChange={() => handleCheckboxChange("agenda")}
                      name="roleClaims"
                      value="agenda"
                    />
                  }
                  label={useLangs("VOTING_ATTENDANT")}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  {EVENT}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={formik.values.roleClaims?.includes("events")}
                      onChange={() => handleCheckboxChange("events")}
                      name="roleClaims"
                      value="events"
                    />
                  }
                  label={useLangs("EVENT_FULLACCESS")}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  {useLangs("ADMINISTRATION_EVENTS")}
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={formik.values.roleClaims?.includes("settings")}
                      onChange={() => handleCheckboxChange("settings")}
                      name="roleClaims"
                      value="settings"
                    />
                  }
                  label={useLangs("SETTINGS")}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  className="defaultBtn mainBtn"
                >
                  {useLangs("SAVE")}
                </Button>
                <Button
                  onClick={() => history("/settings")}
                  className="defaultBtn"
                >
                  {useLangs("CANCEL")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AddEditRole;
