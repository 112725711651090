
export interface IReducerState {
    lang: "RU" | "KZ"
}

export interface IReducerAction {
    type: string;
    lang: "RU" | "KZ";
}

export default (state: IReducerState, action: IReducerAction) => {
    switch (action.type) {
        case 'SET_LANG':
            return {
                ...state,
                lang: action.lang
            }
        default:
            return state
    }
}