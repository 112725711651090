import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useLangs } from "../../../application/hooks/useLangs";
// @ts-ignore
import FileViewer from "react-file-viewer";

type FileViewDialogProps = {
  fileName: string;
  downloadPath: string;
  open: boolean;
  onClose: () => void;
};

const FileViewDialog: React.FC<FileViewDialogProps> = ({
  fileName,
  downloadPath,
  open,
  onClose,
}) => {
  return (
    <div>
      <Dialog
        className="dialogContent"
        fullWidth
        maxWidth={"xl"}
        open={open}
        onClose={onClose}
      >
        <DialogTitle style={{ color: "#004479" }}>
          {useLangs("VIEW_FILE")}
        </DialogTitle>
        <DialogContent>
          <FileViewer
            fileType={fileName.substr(fileName.lastIndexOf(".") + 1)}
            filePath={downloadPath}
            errorComponent={<h2>Произошла ошибка при открытии.</h2>}
            unsupportedComponent={() => <h2>Файл недоступен для просмотра.</h2>}
            onError={() => {
              console.log("error");
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{useLangs("CLOSE")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileViewDialog;
