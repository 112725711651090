import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { eventsService, questionsService } from "../service";
import {
  ActiveEventVm,
  EventQuestionDto,
  EventQuestionStatus,
  QuestionDetailVm,
} from "../service/ApiClient";
import authService from "../service/AuthService";

export const loadAgendaPageEventAsync = createAsyncThunk(
  "agenda/getCurrentActiveEvent",
  async () => {
    const event = await eventsService.getCurrentActiveEvent();
    return event;
  }
);

export const loadQuestionsToVote = createAsyncThunk(
  "agenda/getQuestionsToVote",
  async (eventId: number) => {
    const questionsToVote = await questionsService.getQuestionsToVote(eventId);
    return questionsToVote;
  }
);

export const loadAgendaUpcomingEventDates = createAsyncThunk(
  "agenda/getUpcomingEventDates",
  async () => {
    const eventsDate: Date[] = await eventsService.getUpcomingEventDates();
    return eventsDate;
  }
);

export interface AgendaState {
  event?: ActiveEventVm | null;
  loading: boolean;
  isVoteModalVisible: boolean;
  isQuestionsToVoteLoading: boolean;
  questions: EventQuestionDto[];
  upcomingEventDates: Date[] | [];
}

const initialState: AgendaState = {
  event: undefined,
  loading: false,
  isVoteModalVisible: false,
  isQuestionsToVoteLoading: false,
  questions: [],
  upcomingEventDates: [],
};

export const agendaSlice = createSlice({
  name: "agenda",
  initialState,
  reducers: {
    setVoteModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isVoteModalVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAgendaPageEventAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadAgendaPageEventAsync.fulfilled,
        (state, action: PayloadAction<ActiveEventVm>) => {
          state.loading = false;
          state.event = Object.assign({}, state.event, action.payload);
          const currentUser = authService.getUser();
          if (
            action.payload.eventQuestions?.some(
              (p) =>
                p.status === EventQuestionStatus.InProgress &&
                p.votes?.filter((x) => x.userId === currentUser.userId)
                  .length === 0
            )
          ) {
            state.isVoteModalVisible = true;
          }
        }
      )
      .addCase(loadAgendaPageEventAsync.rejected, (state) => {
        state.event = undefined;
        state.loading = false;
      });

    builder
      .addCase(loadQuestionsToVote.pending, (state) => {
        state.isQuestionsToVoteLoading = true;
      })
      .addCase(
        loadQuestionsToVote.fulfilled,
        (state, action: PayloadAction<QuestionDetailVm[]>) => {
          state.isQuestionsToVoteLoading = false;
          const questionIds = action.payload.flatMap((x) => x.id);
          const questions = state.event?.eventQuestions?.filter(
            (x) => questionIds.indexOf(x.id) >= 0
          );
          state.questions = questions || [];
        }
      )
      .addCase(loadQuestionsToVote.rejected, (state) => {
        state.isQuestionsToVoteLoading = false;
      });

    builder
      .addCase(loadAgendaUpcomingEventDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadAgendaUpcomingEventDates.fulfilled,
        (state, action: PayloadAction<Date[]>) => {
          state.upcomingEventDates = action.payload;
          state.loading = false;
        }
      )
      .addCase(loadAgendaUpcomingEventDates.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setVoteModalVisible } = agendaSlice.actions;

export default agendaSlice.reducer;
