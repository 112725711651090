import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  AccountClient,
  EventsClient,
  EventStatus,
  ActionType,
  AccountType,
  EventDetailVm,
  AccountVm,
  EventDocumentsClient,
  EventDocumentListItemDto,
  LanguageCode,
  AppException,
} from "../../../application/service/ApiClient";
import { useSnackbar } from "notistack";
import MainFormComponent from "../../component/events/MainFormComponent";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useLangs } from "../../../application/hooks/useLangs";
import Dropzone from "react-dropzone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { getSubString } from "../../../application/helpers/getSubString";
import { saveAs } from "file-saver";
import { eventsService } from "../../../application/service";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { LoadingButton } from "@mui/lab";
import ParticipantsFormContainer from "./EditSubforms/ParticipantsFormContainer";
import QuestionsForm from "./EditSubforms/QuestionsFormContainer";
import PublicationsFormContainer from "./EditSubforms/PublicationsFormContainer";
import DecisionFormContainer from "./EditSubforms/DecisionFormContainer";
import ProtocolFormContainer from "./EditSubforms/ProtocolFormContainer";

export interface IQuestionForm {
  title: string;
  proposedBy: string;
  type: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddEditEvent = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [delegateAccounts, setDelegateAccounts] = useState<AccountVm[]>([]);
  const [invitedUsers, setInvitedAccounts] = useState<AccountVm[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [event, setEvent] = useState<EventDetailVm | undefined>();
  const [eventId, setEventId] = useState<number | undefined>(
    Number(id) || undefined
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [eventDocuments, setEventDocuments] = useState<
    EventDocumentListItemDto[]
  >([]);

  const LANG = {
    START: useLangs("START"),
    STOP: useLangs("STOP"),
    SAVE: useLangs("SAVE"),
    DECISION: useLangs("DECISION"),
    BACK: useLangs("BACK"),
    CREATE_EVENT: useLangs("CREATE_EVENT"),
    IN_KAZAKH: useLangs("IN_KAZAKH"),
    IN_RUSSIAN: useLangs("IN_RUSSIAN"),
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStep(newValue);
  };

  const formik = useFormik<EventDetailVm>({
    initialValues: {
      title: "",
      startDate: undefined,
      type: "",
      description: "",
    },
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      if (activeStep === 0) {
        editEventInfo(values);
      }
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const isReadOnly =
    formik.values.status === EventStatus.Completed ||
    formik.values.status === EventStatus.Deleted;

  const navigate = useNavigate();
  const ERROR = useLangs("ERROR");
  const SUCCESSFULLY_EVENT_UPDATED = useLangs("SUCCESSFULLY_EVENT_UPDATED");
  const EVENT_ALREADY_RUNNING = useLangs("EVENT_ALREADY_RUNNING");
  const CANCEL = useLangs("CANCEL");

  const loadUsers = async () => {
    try {
      const accountsClient = new AccountClient();
      const accounts = await accountsClient.getAccounts(AccountType.Delegates);
      const invitedUsers = await accountsClient.getAccounts(
        AccountType.Invited
      );
      setDelegateAccounts(accounts);
      setInvitedAccounts(invitedUsers);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEvent();
    getEventDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getEvent = async () => {
    if (eventId !== undefined) {
      setLoading(true);
      try {
        const eventsClient = new EventsClient();
        const event = await eventsClient.getEvent(Number(eventId));
        formik.setValues(event);
        setEvent(event);
        // setQuestionList(event.eventQuestions || [])
      } catch (e) {
        console.error(e);
        enqueueSnackbar(ERROR, { variant: "error" });
      }
      setLoading(false);
    }
  };
  const ABSENT_QUESTIONS = useLangs("ABSENT_QUESTIOS");
  const ABSENT_DELEGATES = useLangs("ABSENT_DELEGATES");
  const WANT_TO_STOP_EVENT = useLangs("WANT_TO_STOP_EVENT");
  const CONFIRM = useLangs("CONFIRM");

  const handleApplyEventAction = async (action: ActionType) => {
    if (eventId !== undefined) {
      try {
        // if (
        //   (action === ActionType.Publish || action === ActionType.Start) &&
        //   formik.values.eventQuestions?.length === 0
        // ) {
        //   return enqueueSnackbar(ABSENT_QUESTIOS, { variant: "info" });
        // } else if (
        //   (action === ActionType.Publish || action === ActionType.Start) &&
        //   formik.values.participants?.length === 0
        // ) {
        //   return enqueueSnackbar(ABSENT_DELEGATES, { variant: "info" });
        // }
        const eventsClient = new EventsClient();
        const applyActionCommand = {
          id: eventId,
          action,
        };
        await eventsClient.applyAction(applyActionCommand);
        getEvent();
      } catch (e) {
        if (e instanceof AppException) {
          const error = JSON.parse(e.response);
          console.log(error);
          if (error.detail === "EVENT_ALREADY_RUNNING") {
            enqueueSnackbar(EVENT_ALREADY_RUNNING, { variant: "error" });
          } else if (error.detail === "ABSENT_QUESTIONS") {
            enqueueSnackbar(ABSENT_QUESTIONS, { variant: "error" });
          } else if (error.detail === "ABSENT_DELEGATES") {
            enqueueSnackbar(ABSENT_DELEGATES, { variant: "error" });
          }
        } else {
          enqueueSnackbar(ERROR, { variant: "error" });
        }
      }
    }
  };

  const editEventInfo = async (values: EventDetailVm) => {
    try {
      let dateValue: Date | undefined = values.startDate;
      if (dateValue !== undefined) {
        var targetTime = new Date(dateValue);
        var timeZoneFromDB = 12;
        var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        dateValue = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
      }
      const eventsClient = new EventsClient();
      const savedEventid = await eventsClient.editEventInfo(
        eventId,
        values.title,
        dateValue,
        values.type,
        values.description
      );
      setEventId(savedEventid);
      enqueueSnackbar(SUCCESSFULLY_EVENT_UPDATED, { variant: "success" });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const handleDropEventFile = async (files: File[]) => {
    try {
      const eventDocumentsClient = new EventDocumentsClient();
      let file = files[0];
      let fileId = await eventDocumentsClient.addEventDocument(eventId, {
        data: file,
        fileName: file.name,
      });
      setEventDocuments([
        ...eventDocuments,
        {
          id: fileId,
          fileName: file.name,
          displayName: file.name,
        },
      ]);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const deleteEventFile = async (id: number) => {
    try {
      const eventDocumentsClient = new EventDocumentsClient();
      await eventDocumentsClient.deleteEventDocument({ id });
      setEventDocuments(eventDocuments.filter((el) => el.id !== id));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const downloadEventFile = async (id: number) => {
    try {
      const eventDocumentsClient = new EventDocumentsClient();
      let eventDocument = await eventDocumentsClient.getEventDocumentById(id);
      let bytes = base64ToArrayBuffer(eventDocument.content || "");
      let blobData = new Blob([bytes], { type: eventDocument.contentType });
      saveAs(blobData, eventDocument.displayName);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
  };

  const base64ToArrayBuffer = (base64: string) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const getEventDocuments = async () => {
    if (eventId !== undefined) {
      try {
        const eventDocumentsClient = new EventDocumentsClient();
        let files = await eventDocumentsClient.getEventDocuments(
          eventId,
          undefined,
          undefined
        );
        setEventDocuments(files.items || []);
      } catch (error) {
        console.error(error);
        enqueueSnackbar(ERROR, { variant: "error" });
      }
    }
  };

  const QUESTIONS = useLangs("QUESTIONS");
  const PARTICIPANTS = useLangs("PARTICIPANTS");
  const PUBLICATION = useLangs("PUBLICATION");
  const EVENT = useLangs("EVENT");
  const PUBLISH = useLangs("PUBLISH");
  const UNPUBLISH = useLangs("UNPUBLISH");
  const ATTACH_FILE = useLangs("ATTACH_FILE");
  const GENERATE_PROTOCOL = useLangs("GENERATE_PROTOCOL");
  const PROTOCOL = useLangs("PROTOCOL");
  const WANT_TO_START_EVENT = useLangs("WANT_TO_START_EVENT");

  const eventTab = {
    headerLabel: EVENT,
    component: <MainFormComponent isReadonly={isReadOnly} formik={formik} />,
  };

  const steps =
    eventId && eventId !== 0
      ? [
          eventTab,
          {
            headerLabel: QUESTIONS,
            component: (
              <QuestionsForm isReadonly={isReadOnly} eventId={event?.id} />
            ),
          },
          {
            headerLabel: PARTICIPANTS,
            component: (
              <ParticipantsFormContainer
                eventId={event?.id}
                delegateAccounts={delegateAccounts}
                invitedAccounts={invitedUsers}
                isReadonly={isReadOnly}
              />
            ),
          },
          {
            headerLabel: PUBLICATION,
            component: <PublicationsFormContainer eventId={event?.id} />,
          },
        ]
      : [eventTab];

  if (event && event.status === EventStatus.Completed) {
    steps.push(
      {
        headerLabel: PROTOCOL,
        component: <ProtocolFormContainer eventId={event?.id} />,
      },
      {
        headerLabel: LANG.DECISION,
        component: <DecisionFormContainer eventId={event?.id} />,
      }
    );
  }

  const generateProtocolMenuState = usePopupState({
    variant: "popover",
    popupId: "generateProtocolMenu",
  });

  const [isProtocolGenerating, setProtocolGenerating] = useState(false);

  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const stopEvent = () => {
    handleOpen();
  };

  const handleStart = () => {
    setStart(true);
  };

  const startEvent = () => {
    handleStart();
  };

  const handleCancle = () => {
    setStart(false);
  };

  const handleGenerateProtocol = async (language: LanguageCode) => {
    generateProtocolMenuState.close();
    try {
      setProtocolGenerating(true);
      const result = await eventsService.generateProtocol(eventId, language);
      saveAs(result.data, result.fileName);
    } catch (e) {
      console.error(e);
    }
    setProtocolGenerating(false);
  };

  const getEventApplyActionBtns = (statusId: EventStatus | undefined) => {
    switch (statusId) {
      case EventStatus.Created:
        return (
          <div>
            <Button onClick={() => handleApplyEventAction(ActionType.Publish)}>
              {PUBLISH}
            </Button>
          </div>
        );
      case EventStatus.Published:
        return (
          <div>
            <Button onClick={() => startEvent()}>{LANG.START}</Button>
            <Button
              onClick={() => handleApplyEventAction(ActionType.CancelPublish)}
            >
              {UNPUBLISH}
            </Button>
          </div>
        );
      case EventStatus.InProgress:
        return (
          <div>
            <Button onClick={() => stopEvent()}>{LANG.STOP}</Button>
          </div>
        );
      case EventStatus.Completed:
        return (
          <div>
            <LoadingButton
              loading={isProtocolGenerating}
              {...bindTrigger(generateProtocolMenuState)}
            >
              {GENERATE_PROTOCOL}
            </LoadingButton>
            <Menu {...bindMenu(generateProtocolMenuState)}>
              <MenuItem onClick={() => handleGenerateProtocol(LanguageCode.Ru)}>
                {LANG.IN_RUSSIAN}
              </MenuItem>
              <MenuItem onClick={() => handleGenerateProtocol(LanguageCode.Kz)}>
                {LANG.IN_KAZAKH}
              </MenuItem>
            </Menu>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <Button
            onClick={() => navigate(-1)}
            style={{ margin: "16px 16px 0" }}
            variant="outlined"
            startIcon={<KeyboardBackspace />}
            className="mainIconBtn"
          >
            {LANG.BACK}
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "10px",
            }}
          >
            <CardHeader title={LANG.CREATE_EVENT} />
            {getEventApplyActionBtns(formik.values.status)}
          </div>
          <Divider />
          <CardContent>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeStep}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="mainTab"
                    >
                      {steps.map((el, i) => (
                        <Tab label={el.headerLabel} {...a11yProps(i)} />
                      ))}
                    </Tabs>
                  </Box>
                  {steps.map((el, i) => (
                    <TabPanel value={activeStep} index={i} key={i}>
                      {el.component}
                    </TabPanel>
                  ))}
                  {(activeStep === 0 || activeStep === 3) && !isReadOnly ? (
                    <Grid className="eventDocumentsGrid" item xs={12} sm={12}>
                      <React.Fragment>
                        <List className="eventDocumentsList">
                          {eventDocuments.map((file, index: number) => (
                            <ListItem
                              className="eventDocumentsListItems"
                              key={index}
                              secondaryAction={
                                activeStep === 0 && !isReadOnly ? (
                                  <IconButton
                                    onClick={() =>
                                      deleteEventFile(file.id || 0)
                                    }
                                    edge="end"
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null
                              }
                            >
                              <ListItemText
                                onClick={() => downloadEventFile(file.id || 0)}
                                primary={getSubString(
                                  15,
                                  file.displayName || ""
                                )}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    </Grid>
                  ) : null}
                  {activeStep === 0 && !isReadOnly && (
                    <Grid
                      xs={12}
                      sm={12}
                      mb={2}
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {eventId && eventId !== 0 ? (
                        <Dropzone onDrop={handleDropEventFile}>
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button component="label">
                                  <AttachFileIcon />
                                  {ATTACH_FILE}
                                </Button>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      ) : (
                        <div></div>
                      )}
                      {event && event.status !== EventStatus.InProgress && (
                        <Button onClick={formik.submitForm}>{LANG.SAVE}</Button>
                      )}
                    </Grid>
                  )}
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {WANT_TO_STOP_EVENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#004479" }}
            onClick={() => handleApplyEventAction(ActionType.Finish)}
          >
            {CONFIRM}
          </Button>
          <Button style={{ color: "#004479" }} onClick={handleClose}>
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={start}
        onClose={handleCancle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {WANT_TO_START_EVENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#004479" }}
            onClick={() => handleApplyEventAction(ActionType.Start)}
          >
            {CONFIRM}
          </Button>
          <Button style={{ color: "#004479" }} onClick={handleCancle}>
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddEditEvent;
