import React, { useEffect, useContext } from "react";
import { CircularProgress, Dialog, Slide, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@material-ui/system";
import AgendaQuestions from "../../component/live/AgendaQuestionsComponent";
import AgendaDocuments from "../../component/live/AgendaDocumentsComponent";
import { RootState } from "../../../application/redux/store";
import { TransitionProps } from "@mui/material/transitions";
import VotingPage from "./VotingPageContainer";
import { useLangs } from "../../../application/hooks/useLangs";
import authService from "../../../application/service/AuthService";
import moment from "moment";
import { Context } from "../../../store/context";
import { loadAgendaUpcomingEventDates } from "../../../application/slices/agendaSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../application/redux/hooks";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgendaContainer() {
  const { event, loading, isVoteModalVisible, upcomingEventDates } =
    useAppSelector((state: RootState) => state.agenda);
  const [value, setValue] = React.useState("questions");
  const dispatch = useAppDispatch();

  const {
    state: { lang },
  } = useContext(Context);

  useEffect(() => {
    if (event === undefined || event === null) {
      dispatch(loadAgendaUpcomingEventDates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const NO_EVENTS = useLangs("NO_EVENTS");
  const QUESTIONS = useLangs("QUESTIONS");
  const DOCUMENTS = useLangs("DOCUMENTS");
  const NEXT_EVENT = useLangs("NEXT_EVENT");

  if (loading) {
    return <CircularProgress />;
  }

  const momentLocalLang = {
    KZ: "kk",
    RU: "ru",
  };

  const changeMoment = () => {
    if (momentLocalLang[lang] === "ru") {
      return "LLL";
    } else return "D MMMM YYYY ж., сағ. HH:mm жоспарланған";
  };

  return !authService.isCurrentUserAnEventParticipant(event) ? (
    upcomingEventDates.length === 0 ? (
      <h1 style={{ textAlign: "center", color: "#004479", marginTop: "18%" }}>
        {NO_EVENTS}
      </h1>
    ) : (
      <h1 style={{ textAlign: "center", color: "#004479", marginTop: "16%" }}>
        {NEXT_EVENT}{" "}
        <p>
          {moment(upcomingEventDates[0])
            .locale(momentLocalLang[lang])
            .format(changeMoment())}
        </p>
      </h1>
    )
  ) : (
    <Box className="tab">
      <TabContext value={value}>
        <Box className="tabMenu">
          <TabList
            onChange={(event: any, newValue: string) => setValue(newValue)}
            className="mainTab"
          >
            <Tab label={QUESTIONS} value="questions" />
            <Tab label={DOCUMENTS} value="documents" />
          </TabList>
        </Box>
        <TabPanel value="questions">
          <AgendaQuestions
            questions={event?.eventQuestions}
            isVoteModalVisible={isVoteModalVisible}
          />
        </TabPanel>
        <TabPanel value="documents">
          <AgendaDocuments eventId={event?.id} />
        </TabPanel>
      </TabContext>

      <Dialog
        fullScreen
        open={isVoteModalVisible}
        TransitionComponent={Transition}
      >
        <VotingPage eventId={event?.id!} />
      </Dialog>
    </Box>
  );
}
