const ILangsKeys = {
  DID_NOT_VOTE: "Не проголосовали/Отсутсвуют",
  CLOSE: "Закрыть",
  PROTOCOL: "ПРОТОКОЛ",
  VIEW_FILE: "Просмотр файла",
  CANNOT_CREATE_QUESTION: "Не удается создать вопрос",
  CANNOT_EDIT_QUESTION: "Не удается обновить вопрос",
  VOTING_IN_PROGRESS: "Идет голосование...",
  GENERATE_PROTOCOL: "Создать Протокол",
  UPLOAD_FROM_FILE: "Загрузить с файла",
  EVENTS_LIST: "Список мероприятий",
  ADD_EVENT: "Добавить",
  ADD: "Добавить",
  MEETING_AKIMAT: "Заседание акимата",
  AGENDA: "Повестка дня",
  START: "Начать",
  STOP: "Остановить",
  QUESTION: "Вопрос",
  UPDATE_QUESTION: "Обновить вопрос",
  ABSENT_QUESTIOS: "Отсутствуют вопросы",
  ABSENT_DELEGATES: "Отсутствуют делегаты",
  QUESTION_NAME: "Наименование вопроса",
  DOCUMENT_NAME: "Наименование документа",
  ADD_QUESTION: "Добавить вопрос",
  COMPLETED_EVENTS: "Завершенные мероприятия",
  ADMINISTRATION_EVENTS: "Администрирование",
  SETTINGS: "Настройки",
  PER: "За",
  AGAINST: "Против",
  EVENT_PUBLICATION: "Публикация мероприятия",
  EVENT: "Мероприятие",
  QUESTIONS: "Вопросы",
  PARTICIPANTS: "Участники",
  PUBLICATION: "Публикация",
  SPEAKER: "Докладчик",
  PUBLISH: "Опубликовать",
  CANCEL: "Отменить",
  VOTING_DETAIL: "Детализация голосования",
  ABSTAINED: "Воздержался",
  ABSENT: "Отсутствовали",
  THERE_ARE_NO_SCHEDULED_EVENTS: "Отсутствуют запланированные мероприятия",
  COMPLETED_EVENTS_LIST: "Список завершенных меропрятий",
  NAME: "Наименование",
  STARTING_DATE_AND_TIME: "Дата и время начала",
  STATUS: "Статус",
  BACK: "Назад",
  SAVE: "Сохранить",
  LAND_COMMISSION: "Земельная комиссия",
  ANOTHER: "Другой",
  DESCRIPTION: "Описание",
  TYPE: "Тип",
  SUCCESSFULLY_ADDED: "Успешно добавлено",
  SUCCESSFULLY_UPDATED: "Успешно обновлено",
  ROLE_SUCCESSFULLY_UPDATED: "Роль успешно обновлена",
  ACCOUNT_SUCCESSFULLY_UPDATED: "Аккаунт успешно обновлена",
  SUCCESSFULLY_DELETED: "Успешно удален",
  ACCOUNT_SUCCESSFULLY_DELETED: "Аккаунт успешно удален",
  SUCCESSFULLY_CREATED: "Успешно создано",
  ROLE_SUCCESSFULLY_CREATED: "Роль успешно создана",
  ACCOUNT_SUCCESSFULLY_CREATED: "Аккаунт успешно создана",
  ERROR: "Ошибка",
  FULL_NAME: "Имя",
  DELEGATES: "Делегаты",
  INVITED: "Приглашенные",
  PROPOSED_BY: "Кем предложено",
  ATTACH_FILE: "Прикрепить файл",
  RUN: "Запустить",
  SUCCESSFULLY_EVENT_CREATED: "Мероприятие успешно создано",
  SUCCESSFULLY_EVENT_UPDATED: "Мероприятие успешно обновлена",
  UNPUBLISH: "Снять с публикации",
  CREATE_EVENT: "Создать мероприятие",
  NAMES: "Имена",
  DECISION_NOT_MADE: "Решение не принято",
  APPROVAL_REGIONAL_AKIMATS:
    "Об утверждении Типовых регламентов акиматов области",
  DOCUMENTS: "Документы",
  ALL: "Все",
  ACTIVE_ONLY: "Активные",
  COMPLETED: "Завершенные",
  DELETE: "Удалить",
  YOU_WANT_DELETE_EVENT: "Вы действительно хотите удалить мероприятие",
  LAUNCH: "Запуск",
  LAUNCH_EVENT: "Запуск голосования",
  RESTART: "Перезапуск",
  RESTART_EVENT: "Рестарт голосования",
  MADE_SPEECH: "Выступил",
  EDIT_ROLE: "Редактировать роль",
  TITLE: "Название",
  RESOLUTION_START_AND_END: "Разрешениe,запуск и завершение",
  RESULTS_DETAIL: "Итоги + детализация",
  EDIT: "Редактировать пользователя",
  FIO: "ФИО",
  GROUP: "Группа",
  ORGANIZATION: "Организация",
  POSITION: "Должность",
  PASSWORD: "Пароль",
  LANG: "Язык",
  KAZAKH: "Казахский",
  RUSSION: "Русский",
  IN_KAZAKH: "На казахском",
  IN_RUSSIAN: "На русском",
  ENGLISH: "Английский",
  LAST_ENTRANCE: "Последний вход",
  ROLES: "Роли",
  CREATED: "Создан",
  ARCHIVED: "Архивирован",
  COMPLETED_STATUS: "Завершен",
  DELETED: "Удален",
  IN_PROGRESS: "В процессе",
  PUBLISHED: "Опубликован",
  VOTING: "Голосование",
  NOT_STARTED: "не начато",
  NO_EVENTS: "Отсутствуют запланированные мероприятия",
  FILES: "Файлы",
  ENDED: "завершено",
  STOP_EVENT: "Завершить мероприятие",
  EVENT_ALREADY_RUNNING: "Идет другое мероприятие",
  CONFIRM: "Подтвердить",
  DECISION: "Решение",
  ATTACH_PHOTO: "Добавить фото",
  ACCEPTED: "Решение принято",
  DECLINED: "Решение не принято",
  NEXT_EVENT: "Cледующее заседание запланировано на",
  DECISION_MAKER: "Решающий голос",
  VOTING_ADMINISTRATION: "Голосование - управление",
  VOTING_TOTALS_DETAIL: "Голосование - итоги и детализация",
  VOTING_ATTENDANT: "Голосование - участник",
  EVENT_FULLACCESS: "Мероприятия - полный доступ",
  VOTING_RESULTS: "Итоги голосования",
  WANT_TO_EXIT: "Вы действительно хотите выйти из системы?",
  WANT_TO_STOP_EVENT: "Вы действительно хотите ЗАВЕРШИТЬ мероприятие?",
  WANT_TO_DELETE: "Вы действительно хотите удалить?",
  ALREADY_EXIST: "Уже существует",
  WANT_TO_START_EVENT: "Вы действительно хотите НАЧАТЬ мероприятие?",
} as const;

export type ILangItems = keyof typeof ILangsKeys;

export interface ILangsValue {
  DID_NOT_VOTE: string;
  CLOSE: string;
  IN_KAZAKH: string;
  IN_RUSSIAN: string;
  PROTOCOL: string;
  VIEW_FILE: string;
  DOCUMENT_NAME: string;
  CANNOT_CREATE_QUESTION: string;
  CANNOT_EDIT_QUESTION: string;
  EVENTS_LIST: string;
  GENERATE_PROTOCOL: string;
  UPLOAD_FROM_FILE: string;
  ADD_EVENT: string;
  ADD: string;
  MEETING_AKIMAT: string;
  AGENDA: string;
  START: string;
  STOP: string;
  QUESTION: string;
  UPDATE_QUESTION: string;
  ABSENT_QUESTIOS: string;
  ABSENT_DELEGATES: string;
  QUESTION_NAME: string;
  ADD_QUESTION: string;
  COMPLETED_EVENTS: string;
  ADMINISTRATION_EVENTS: string;
  SETTINGS: string;
  PER: string;
  AGAINST: string;
  EVENT_PUBLICATION: string;
  EVENT: string;
  QUESTIONS: string;
  PARTICIPANTS: string;
  PUBLICATION: string;
  SPEAKER: string;
  PUBLISH: string;
  CANCEL: string;
  VOTING_DETAIL: string;
  ABSTAINED: string;
  ABSENT: string;
  THERE_ARE_NO_SCHEDULED_EVENTS: string;
  COMPLETED_EVENTS_LIST: string;
  NAME: string;
  STARTING_DATE_AND_TIME: string;
  STATUS: string;
  BACK: string;
  SAVE: string;
  LAND_COMMISSION: string;
  ANOTHER: string;
  DESCRIPTION: string;
  TYPE: string;
  SUCCESSFULLY_ADDED: string;
  SUCCESSFULLY_UPDATED: string;
  ROLE_SUCCESSFULLY_UPDATED: string;
  ACCOUNT_SUCCESSFULLY_UPDATED: string;
  SUCCESSFULLY_DELETED: string;
  ACCOUNT_SUCCESSFULLY_DELETED: string;
  SUCCESSFULLY_CREATED: string;
  ROLE_SUCCESSFULLY_CREATED: string;
  ACCOUNT_SUCCESSFULLY_CREATED: string;
  ERROR: string;
  FULL_NAME: string;
  DELEGATES: string;
  INVITED: string;
  PROPOSED_BY: string;
  ATTACH_FILE: string;
  RUN: string;
  SUCCESSFULLY_EVENT_CREATED: string;
  SUCCESSFULLY_EVENT_UPDATED: string;
  UNPUBLISH: string;
  CREATE_EVENT: string;
  NAMES: string;
  DECISION_NOT_MADE: string;
  APPROVAL_REGIONAL_AKIMATS: string;
  DOCUMENTS: string;
  ALL: string;
  ACTIVE_ONLY: string;
  COMPLETED: string;
  DELETE: string;
  YOU_WANT_DELETE_EVENT: string;
  LAUNCH: string;
  LAUNCH_EVENT: string;
  RESTART: string;
  RESTART_EVENT: string;
  MADE_SPEECH: string;
  EDIT_ROLE: string;
  TITLE: string;
  RESOLUTION_START_AND_END: string;
  RESULTS_DETAIL: string;
  EDIT: string;
  FIO: string;
  GROUP: string;
  ORGANIZATION: string;
  POSITION: string;
  PASSWORD: string;
  LANG: string;
  KAZAKH: string;
  RUSSION: string;
  ENGLISH: string;
  LAST_ENTRANCE: string;
  ROLES: string;
  CREATED: string;
  ARCHIVED: string;
  COMPLETED_STATUS: string;
  DELETED: string;
  IN_PROGRESS: string;
  PUBLISHED: string;
  VOTING: string;
  NOT_STARTED: string;
  NO_EVENTS: string;
  FILES: string;
  ENDED: string;
  STOP_EVENT: string;
  EVENT_ALREADY_RUNNING: string;
  CONFIRM: string;
  DECISION: string;
  ATTACH_PHOTO: string;
  VOTING_IN_PROGRESS: string;
  ACCEPTED: string;
  DECLINED: string;
  NEXT_EVENT: string;
  DECISION_MAKER: string;
  VOTING_ADMINISTRATION: string;
  VOTING_TOTALS_DETAIL: string;
  VOTING_ATTENDANT: string;
  EVENT_FULLACCESS: string;
  VOTING_RESULTS: string;
  WANT_TO_EXIT: string;
  WANT_TO_STOP_EVENT: string;
  WANT_TO_DELETE: string;
  ALREADY_EXIST: string;
  WANT_TO_START_EVENT: string;
}

export interface ILangs {
  RU: ILangsValue;
  KZ: ILangsValue;
}

export const LANGS: ILangs = {
  RU: {
    DID_NOT_VOTE: "Не проголосовали/Отсутсвуют",
    CLOSE: "Закрыть",
    IN_KAZAKH: "На казахском",
    IN_RUSSIAN: "На русском",
    PROTOCOL: "ПРОТОКОЛ",
    VIEW_FILE: "Просмотр файла",
    DOCUMENT_NAME: "Наименование документа",
    CANNOT_CREATE_QUESTION: "Не удается создать вопрос",
    CANNOT_EDIT_QUESTION: "Не удается обновить вопрос",
    EVENTS_LIST: "Список мероприятий",
    GENERATE_PROTOCOL: "Создать Протокол",
    UPLOAD_FROM_FILE: "Загрузить с файла",
    ADD_EVENT: "Добавить",
    ADD: "Добавить",
    MEETING_AKIMAT: "Заседание акимата",
    AGENDA: "Повестка дня",
    START: "Начать",
    STOP: "Остановить",
    QUESTION: "Вопрос",
    UPDATE_QUESTION: "Обновить вопрос",
    ABSENT_QUESTIOS: "Отсутствуют вопросы",
    ABSENT_DELEGATES: "Отсутствуют делегаты",
    QUESTION_NAME: "Наименование вопроса",
    ADD_QUESTION: "Добавить вопрос",
    COMPLETED_EVENTS: "Завершенные мероприятия",
    ADMINISTRATION_EVENTS: "Администрирование",
    SETTINGS: "Настройки",
    PER: "За",
    AGAINST: "Против",
    EVENT_PUBLICATION: "Публикация мероприятия",
    EVENT: "Мероприятие",
    QUESTIONS: "Вопросы",
    PARTICIPANTS: "Участники",
    PUBLICATION: "Публикация",
    SPEAKER: "Докладчик",
    PUBLISH: "Опубликовать",
    CANCEL: "Отменить",
    VOTING_DETAIL: "Детализация голосования",
    ABSTAINED: "Воздержался",
    ABSENT: "Отсутствовали",
    THERE_ARE_NO_SCHEDULED_EVENTS: "Отсутствуют запланированные мероприятия",
    COMPLETED_EVENTS_LIST: "Список завершенных меропрятий",
    NAME: "Наименование",
    STARTING_DATE_AND_TIME: "Дата и время начала",
    STATUS: "Статус",
    BACK: "Назад",
    SAVE: "Сохранить",
    LAND_COMMISSION: "Земельная комиссия",
    ANOTHER: "Другой",
    DESCRIPTION: "Описание",
    TYPE: "Тип",
    SUCCESSFULLY_ADDED: "Успешно добавлено",
    SUCCESSFULLY_UPDATED: "Успешно обновлено",
    ROLE_SUCCESSFULLY_UPDATED: "Роль успешно обновлена",
    ACCOUNT_SUCCESSFULLY_UPDATED: "Аккаунт успешно обновлена",
    SUCCESSFULLY_DELETED: "Успешно удален",
    ACCOUNT_SUCCESSFULLY_DELETED: "Аккаунт успешно удален",
    SUCCESSFULLY_CREATED: "Успешно создано",
    ROLE_SUCCESSFULLY_CREATED: "Роль успешно создана",
    ACCOUNT_SUCCESSFULLY_CREATED: "Аккаунт успешно создана",
    ERROR: "Ошибка",
    FULL_NAME: "Имя",
    DELEGATES: "Делегаты",
    INVITED: "Приглашенные",
    PROPOSED_BY: "Кем предложено",
    ATTACH_FILE: "Прикрепить файл",
    RUN: "Запустить",
    SUCCESSFULLY_EVENT_CREATED: "Мероприятие успешно создано",
    SUCCESSFULLY_EVENT_UPDATED: "Мероприятие успешно обновлена",
    UNPUBLISH: "Снять с публикации",
    CREATE_EVENT: "Создать мероприятие",
    NAMES: "Имена",
    DECISION_NOT_MADE: "Решение не принято",
    APPROVAL_REGIONAL_AKIMATS:
      "Об утверждении Типовых регламентов акиматов области",
    DOCUMENTS: "Документы",
    ALL: "Все",
    ACTIVE_ONLY: "Активные",
    COMPLETED: "Завершенные",
    DELETE: "Удалить",
    YOU_WANT_DELETE_EVENT: "Вы действительно хотите удалить мероприятие",
    LAUNCH: "Запуск",
    LAUNCH_EVENT: "Запуск голосования",
    RESTART: "Перезапуск",
    RESTART_EVENT: "Рестарт голосования",
    MADE_SPEECH: "Выступил",
    EDIT_ROLE: "Редактировать роль",
    TITLE: "Название",
    RESOLUTION_START_AND_END: "Разрешениe,запуск и завершение",
    RESULTS_DETAIL: "Итоги + детализация",
    EDIT: "Редактировать пользователя",
    FIO: "ФИО",
    GROUP: "Группа",
    ORGANIZATION: "Организация",
    POSITION: "Должность",
    PASSWORD: "Пароль",
    LANG: "Язык",
    KAZAKH: "Казахский",
    RUSSION: "Русский",
    ENGLISH: "Английский",
    LAST_ENTRANCE: "Последний вход",
    ROLES: "Роли",
    CREATED: "Создан",
    ARCHIVED: "Архивирован",
    COMPLETED_STATUS: "Завершен",
    DELETED: "Удален",
    IN_PROGRESS: "В процессе",
    PUBLISHED: "Опубликован",
    VOTING: "Голосование",
    VOTING_IN_PROGRESS: "Идет голосование...",
    NOT_STARTED: "не начато",
    NO_EVENTS: "Отсутствуют запланированные мероприятия",
    FILES: "Файлы",
    ENDED: "завершено",
    STOP_EVENT: "Завершить мероприятие",
    EVENT_ALREADY_RUNNING: "Идет другое мероприятие",
    CONFIRM: "Подтвердить",
    DECISION: "Решение",
    ATTACH_PHOTO: "Добавить фото",
    ACCEPTED: "Решение принято",
    DECLINED: "Решение не принято",
    NEXT_EVENT: "Cледующее заседание запланировано на",
    DECISION_MAKER: "Решающий голос",
    VOTING_ADMINISTRATION: "Голосование - управление",
    VOTING_TOTALS_DETAIL: "Голосование - итоги и детализация",
    VOTING_ATTENDANT: "Голосование - участник",
    EVENT_FULLACCESS: "Мероприятия - полный доступ",
    VOTING_RESULTS: "Итоги голосования",
    WANT_TO_EXIT: "Вы действительно хотите выйти из системы?",
    WANT_TO_STOP_EVENT: "Вы действительно хотите ЗАВЕРШИТЬ мероприятие?",
    WANT_TO_DELETE: "Вы действительно хотите удалить?",
    ALREADY_EXIST: "Уже существует",
    WANT_TO_START_EVENT: "Вы действительно хотите НАЧАТЬ мероприятие?",
  },
  KZ: {
    DID_NOT_VOTE: "Дауыс бермеді",
    CLOSE: "Жабу",
    IN_KAZAKH: "Қазакша",
    IN_RUSSIAN: "Орысша",
    PROTOCOL: "ХАТТАМА",
    VIEW_FILE: "Файлды қарау",
    DOCUMENT_NAME: "Құжат атауы",
    CANNOT_CREATE_QUESTION: "Сұрақ қосу мүмкін емес",
    CANNOT_EDIT_QUESTION: "Сұрақ жаңарту мүмкін емес",
    EVENTS_LIST: "Іс-шаралар тізімі",
    GENERATE_PROTOCOL: "Хаттама жасау",
    UPLOAD_FROM_FILE: "Файлдан алу",
    ADD_EVENT: "Қосу",
    ADD: "Қосу",
    MEETING_AKIMAT: "Әкімiшілік отырысы",
    AGENDA: "Күн тәртібі",
    START: "Бастау",
    STOP: "Тоқтату",
    QUESTION: "Сұрақ",
    QUESTION_NAME: "Сұрақ атауы",
    UPDATE_QUESTION: "Сұрақ жаңарту",
    ABSENT_QUESTIOS: "Сұрақтар жоқ",
    ABSENT_DELEGATES: "Делегаттар тагайындалмаган",
    ADD_QUESTION: "Сұрақ қосу",
    COMPLETED_EVENTS: "Аяқталған іс-шаралар",
    ADMINISTRATION_EVENTS: "Әкімшілік",
    SETTINGS: "Баптамалар",
    PER: "Жақтады",
    AGAINST: "Қарсы",
    EVENT_PUBLICATION: "Іс-шараның жариялануы",
    EVENT: "Іс-шара",
    QUESTIONS: "Сұрақтар",
    PARTICIPANTS: "Қатысушылар",
    PUBLICATION: "Басылым",
    SPEAKER: "Баяндаушы",
    PUBLISH: "Жариялау",
    CANCEL: "Бас тарту",
    VOTING_DETAIL: "Дауыс беру мәліметі",
    ABSTAINED: "Қалыс қалды",
    ABSENT: "Қатыспағандар",
    THERE_ARE_NO_SCHEDULED_EVENTS: "Жоспарланған іс-шаралар жоқ",
    COMPLETED_EVENTS_LIST: "Аяқталған әрекеттер тізімі",
    STARTING_DATE_AND_TIME: "Басталу күні мен уақыты",
    STATUS: "Күй",
    NAME: "Аты",
    BACK: "Артқа",
    SAVE: "Сақтау",
    LAND_COMMISSION: "Жер комиссиясы",
    ANOTHER: "Басқа",
    DESCRIPTION: "Сипаттама",
    TYPE: "Түрі",
    SUCCESSFULLY_ADDED: "Сәтті қосылды",
    SUCCESSFULLY_UPDATED: "Сәтті жаңартылды",
    ROLE_SUCCESSFULLY_UPDATED: "Рөл сәтті жаңартылды",
    ACCOUNT_SUCCESSFULLY_UPDATED: "Аккаунт сәтті жаңартылды",
    SUCCESSFULLY_DELETED: "Сәтті өшірілді",
    ACCOUNT_SUCCESSFULLY_DELETED: "Аккаунт сәтті өшірілді",
    SUCCESSFULLY_CREATED: "Сәтті жасалды",
    ROLE_SUCCESSFULLY_CREATED: "Рөл сәтті жасалды",
    ACCOUNT_SUCCESSFULLY_CREATED: "Аккаунт сәтті жасалды",
    ERROR: "Қате",
    FULL_NAME: "Аты",
    DELEGATES: "Делегаттар",
    INVITED: "Шақырылған",
    PROPOSED_BY: "Ұсынған",
    ATTACH_FILE: "Файлды тіркеу",
    RUN: "Жүгірту",
    SUCCESSFULLY_EVENT_CREATED: "Іс-шара сәтті жасалды",
    SUCCESSFULLY_EVENT_UPDATED: "Аккаунт сәтті жаңартылды",
    UNPUBLISH: "Жариялаудан алып тастау",
    CREATE_EVENT: "Іс-шара жасау",
    NAMES: "Аттары",
    DECISION_NOT_MADE: "Шешім қабылданбады",
    APPROVAL_REGIONAL_AKIMATS:
      "Облыс әкімдіктерінің Үлгілік ережесін бекіту туралы",
    DOCUMENTS: "Құжаттар",
    ALL: "Барлығы",
    ACTIVE_ONLY: "Белсенділері",
    COMPLETED: "Аяқталғандары",
    DELETE: "Өшіру",
    YOU_WANT_DELETE_EVENT: "Сіз іс-шараны өшіргіңіз келеді ме",
    LAUNCH: "Іске қосу",
    LAUNCH_EVENT: "Дауыс беруді іске қосу",
    RESTART: "Қайтадан іске қосу",
    RESTART_EVENT: "Дауыс беруді қайтадан іске қосу",
    MADE_SPEECH: "Баяндама жасады",
    EDIT_ROLE: "Рөлді өзгерту",
    TITLE: "Атауы",
    RESOLUTION_START_AND_END: "Шешім, бастау және аяқтау",
    RESULTS_DETAIL: "Нәтижелер + детализация",
    EDIT: "Қолданушыны өзгерту",
    FIO: "Аты-жөні",
    GROUP: "Группасы",
    ORGANIZATION: "Ұйым",
    POSITION: "Мансабы",
    PASSWORD: "Құпия сөз",
    LANG: "Тілі",
    KAZAKH: "Казақша",
    RUSSION: "Орысша",
    ENGLISH: "Ағылшынша",
    LAST_ENTRANCE: "Соңғы рет кірді",
    ROLES: "Рөлдер",
    CREATED: "Жасалған",
    ARCHIVED: "Мұрағатталды",
    COMPLETED_STATUS: "Аяқталды",
    DELETED: "Жойылды",
    IN_PROGRESS: "Процесте",
    PUBLISHED: "Жарияланды",
    VOTING: "Дауыс беру",
    NOT_STARTED: "басталмады",
    NO_EVENTS: "Жоспарланған іс-шаралар жоқ",
    FILES: "Файлдар",
    ENDED: "аяқталды",
    STOP_EVENT: "Іс-шараны аяқтау",
    EVENT_ALREADY_RUNNING: "Басқа іс-шара жүруде",
    CONFIRM: "Құптау",
    DECISION: "Шешім",
    ATTACH_PHOTO: "Фото тіркеу",
    VOTING_IN_PROGRESS: "Дауыс беру жүріп жатыр...",
    ACCEPTED: "Шешім кабылданды",
    DECLINED: "Шешім қабылданбады",
    NEXT_EVENT: "Келесі отырыс",
    DECISION_MAKER: "Шешуші дауыс",
    VOTING_ADMINISTRATION: "Дауыс беру - басқару",
    VOTING_TOTALS_DETAIL: "Дауыс беру - қорытындылар мен детализация",
    VOTING_ATTENDANT: "Дауыс беру - қатысушы",
    EVENT_FULLACCESS: "Іс-шаралар - толық құқық",
    VOTING_RESULTS: "Дауыс беру нәтижелері",
    WANT_TO_EXIT: "Шыққыңыз келетінінізге сенімдісіз бе?",
    WANT_TO_STOP_EVENT: "Іс-шараны токтатуыңыз келетінінізге сенімдісіз бе?",
    WANT_TO_DELETE: "Жойғыңыз келетінінізге сенімдісіз бе?",
    ALREADY_EXIST: "Уже существует",
    WANT_TO_START_EVENT: "Іс-шараны бастағыңыз келетінінізге сенімдісіз бе?",
  },
};
