import jwtDecode from "jwt-decode";
import { AccountClient, ActiveEventVm } from "./ApiClient";
export const ClaimCode = {
  VotingActions: "voting_actions",
  VotingDetails: "voting_details",
  Settings: "settings",
  Agenda: "agenda",
  Events: "events",
};
export interface ApplicationUser {
  sub: string;
  jti: string;
  role: string;
  Permission: string[];
  [key: string]: any;
}

type userLangType = "ru" | "kz";

class AuthService {
  login(token: string) {
    sessionStorage.setItem("user", token);
  }

  setLang(lang: string) {
    sessionStorage.setItem("userLang", lang);
  }

  getLang(): userLangType {
    let userLangValue = sessionStorage.getItem("userLang");
    let userLang: userLangType =
      userLangValue !== null &&
      (userLangValue === "kz" || userLangValue === "ru")
        ? userLangValue
        : "ru";
    return userLang;
  }

  logout() {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userLang");
  }

  isAuthenticated() {
    const user = this.getUser();
    return !!user;
  }

  getUser() {
    const token = sessionStorage.getItem("user");
    if (token == null) {
      return null;
    }
    let decodedToken: any = jwtDecode(token);
    if (decodedToken.hasOwnProperty("Permission")) {
      if (!Array.isArray(decodedToken.Permission)) {
        decodedToken.Permission = [decodedToken.Permission];
      }
    } else {
      decodedToken.Permission = [];
    }
    return decodedToken;
  }

  getAccessToken() {
    return sessionStorage.getItem("user");
  }

  isCurrentUserADelegate() {
    const user: any = this.getUser();
    if (user == null) {
      return false;
    }
    if (user.Permission.some((x: string) => x === ClaimCode.Agenda)) {
      return true;
    }

    return false;
  }

  isCurrentUserAPanel() {
    const user: any = this.getUser();
    if (user == null) {
      return false;
    }
    if (
      user.Permission.length === 1 &&
      user.Permission.some((x: string) => x === ClaimCode.VotingDetails)
    ) {
      return true;
    }

    return false;
  }

  isCurrentUserAnAdmin() {
    const user: any = this.getUser();
    if (user == null) {
      return false;
    }
    if (
      user.Permission.some((x: string) => x === ClaimCode.VotingDetails) &&
      user.Permission.some((x: string) => x === ClaimCode.VotingActions) &&
      user.Permission.some((x: string) => x === ClaimCode.Settings) &&
      user.Permission.some((x: string) => x === ClaimCode.Events)
    ) {
      return true;
    }

    return false;
  }

  isCurrentUserAnEventParticipant(event: ActiveEventVm | null | undefined) {
    const user: any = this.getUser();
    if (user == null) {
      return false;
    }
    if (event === undefined) {
      return false;
    }
    if (this.isCurrentUserADelegate()) {
      if (event) {
        let ii = event?.participants?.findIndex(
          (x) => x.userId === user.userId
        );
        if (ii! > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async getPhoto() {
    const accountClient = new AccountClient();
    try {
      const photo = await accountClient.getPhoto(undefined);

      return photo;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthService();

export default authService;
