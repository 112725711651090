import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { PaginatedListOfQuestionVm } from "../../../../application/service/ApiClient";
import { useLangs } from "../../../../application/hooks/useLangs";
import { questionsService } from "../../../../application/service";
import { useSnackbar } from "notistack";

interface Props {
  eventId?: number;
}

const PublicationsFormContainer: React.FC<Props> = ({ eventId }) => {
  const ERROR = useLangs("ERROR");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const pageSizeQuestionList = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<
    PaginatedListOfQuestionVm | undefined
  >(undefined);

  const getEventQuestions = async () => {
    setLoading(true);
    try {
      const response = await questionsService.getQuestions(
        eventId,
        currentPage + 1,
        10
      );
      setQuestions(response);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(ERROR, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        let rowIndex =
          (questions &&
            questions.items!.findIndex((el) => el.id === params.id)) ||
          0;

        return currentPage * pageSizeQuestionList + rowIndex + 1;
      },
    },
    {
      field: "title",
      headerName: useLangs("QUESTION_NAME"),
      width: 600,
    },
    {
      field: "proposedBy",
      headerName: useLangs("PROPOSED_BY"),
      width: 600,
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.table}>
        <DataGrid
          rows={(questions && questions?.items) || []}
          columns={columns}
          page={currentPage}
          disableSelectionOnClick
          disableColumnMenu
          pagination
          paginationMode="server"
          loading={loading}
          rowCount={questions?.totalCount}
          rowsPerPageOptions={[10]}
          pageSize={pageSizeQuestionList}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
        />
      </div>
    </React.Fragment>
  );
};
const useStyles = makeStyles({
  table: {
    height: 650,
    width: "100%",
  },
});

export default PublicationsFormContainer;
